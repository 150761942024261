import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { dispatchEvent } from './utils/events';
import { isPlainObject } from './utils/helpers';
import { extractDecorationParams, extractAttributionParams } from './utils/url';

import type { McfxConfigOptions, SessionState } from './declarations';

/**
 * Session Tracking
 * @param {Required<McfxConfigOptions>} MCFX Configuration Options
 */
export class Session {
  readonly _values: Map<string, any>;
  readonly visitorSessionLength: number;
  readonly cookieDomain: string;
  readonly useSecureCookies: boolean;
  readonly agentUrl: string;

  constructor(configuration: McfxConfigOptions) {
    this.visitorSessionLength = configuration.visitorSessionLength;
    this.cookieDomain = configuration.cookieDomain;
    this.useSecureCookies = configuration.useSecureCookies;
    this._values = new Map();
    this.agentUrl = configuration.agentUrl;

    this.createSession();
  }

  get validTTL(): boolean {
    return new Date().getTime() < this._values.get('ttl');
  }

  get cookiedUID(): string | undefined {
    const _fx = Cookies.get('__fx');
    const fx_uuid = Cookies.get('fx_uuid');

    if (_fx !== 'undefined') {
      return _fx;
    }

    if (fx_uuid !== 'undefined') {
      return fx_uuid;
    }

    return undefined;
  }

  createSession(): SessionState {
    const currentTime = new Date().getTime();
    const local = window.localStorage.getItem('__fx');
    const store = local ? JSON.parse(local) : {};

    const session: SessionState = {
      sid: uuid(),
      pg: Cookies.get('mcfxPGID'), // legacy personalize
      r: document.referrer,
      // overide defaults with restorable session
      ...(currentTime < store?.ttl ? store : {}),

      uid: this.cookiedUID || store.uid || uuid(),
      gaId: Cookies.get('_ga'),
      ...extractDecorationParams(),
    };

    // always restore fingerprint if it was already done.
    if (store.fingerprint) {
      session.fingerprint = store.fingerprint;
    }

    this.set(session);

    if (window.gtag) {
      window.gtag('set', 'user_properties', {
        mcfxVisitorId: this.get('uid'),
        mcfxSession: this.get('sid'),
        gaId: this.get('gaId'),
      });
    }

    dispatchEvent('session:created', session);
    return session;
  }

  /**
   * cache the session to users local storage
   * @private
   * @param {object} values to cacheToLocalStorage
   */
  private cacheToLocalStorage() {
    window.localStorage.setItem(
      '__fx',
      JSON.stringify({
        ...this.get(),
      })
    );

    Cookies.set('__fx', this.get('uid'), {
      path: '/',
      domain: this.cookieDomain,
      secure: this.useSecureCookies,
      expires: 365 * 10,
    });

    dispatchEvent('session:saved', this.get());
  }

  /**
   * Gets the value of one or more properties in the session.
   * @param prop The name of the property to get, or an array of property names to get.
   * @returns The value of the property (if `prop` is a string), an object containing key-value pairs of all properties (if `prop` is not provided), or an object containing key-value pairs of the specified properties (if `prop` is an array).
   */
  get(prop?: string | string[]): any | Record<string, any> {
    if (!prop || (Array.isArray(prop) && !prop.length)) {
      return Object.fromEntries(this._values.entries());
    }

    if (Array.isArray(prop)) {
      return prop.reduce((out, p) => {
        out[p] = this._values.get(p);
        return out;
      }, {});
    }

    return this._values.get(prop);
  }

  /**
   * Sets one or more properties in the session.
   * @param prop The name of the property to set, or an object containing key-value pairs to set.
   * @param _value The value to set the property to (if `prop` is a string).
   * @returns void
   */
  set(prop: string | Record<string, any>, _value?: any): void {
    if (isPlainObject(prop)) {
      Object.entries(prop).forEach(([key, value]) => {
        this._values.set(key, value);
      });
    } else {
      this._values.set(prop as string, _value);
    }
    this._values.set('ttl', new Date().getTime() + 1000 * 60 * this.visitorSessionLength);
    this.cacheToLocalStorage();
  }

  /**
   *
   * @param attribution attribution to set into session
   */
  legacySetVisitor(prop: string | Record<string, any>, _value?: any): void {
    let data = {} as Record<string, any>;
    if (typeof prop === 'string') {
      data[prop] = _value;
    } else {
      data = prop;
    }

    const { l, s, m, c, sc, st, ip, fp, ...session } = data;
    const post = {
      location: l,
      attribution: {
        source: s,
        medium: m,
        channel: c,
        subchannel: sc,
        term: st,
      },
      s,
      m,
      st,
      ip,
      ...session,
    };

    this.set(post);
  }

  /**
   *
   * @param attribution attribution to set into session
   */
  getPostValues(): Record<string, any> {
    const { location = {}, attribution = {}, fingerprint, ...session } = this.get();

    const post = {
      ...session,
      l: location,
      s: session.s || attribution.source,
      m: session.m || attribution.medium,
      c: attribution.channel,
      sc: attribution.subchannel,
      st: session.st || attribution.term,
      ip: location.ip,
      fp: fingerprint,
      ...extractAttributionParams(attribution.tracking),
      ...extractAttributionParams(attribution.ad),
    };

    return post;
  }
}
