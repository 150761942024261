import { hashMini } from './utils/crypto';
import { hashSlice } from './utils/helpers';

export function getCSS() {
  try {
    // get CSSStyleDeclaration
    const cssStyleDeclaration = getComputedStyle(document.body);

    if (!cssStyleDeclaration) {
      return undefined;
    }
    // get properties
    const proto = Object.getPrototypeOf(cssStyleDeclaration);
    const prototypeProperties = Object.getOwnPropertyNames(proto);
    const ownEnumerablePropertyNames = [];
    const cssVar = /^--.*$/;

    Object.keys(cssStyleDeclaration).forEach((key) => {
      const numericKey = !isNaN(+key);
      const value = cssStyleDeclaration[key];
      const customPropKey = cssVar.test(key);
      const customPropValue = cssVar.test(value);

      if (numericKey && !customPropValue) {
        return ownEnumerablePropertyNames.push(value);
      } else if (!numericKey && !customPropKey) {
        return ownEnumerablePropertyNames.push(key);
      }
    });

    // get properties in prototype chain (required only in chrome)
    const propertiesInPrototypeChain = {};
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const uncapitalize = (str) => str.charAt(0).toLowerCase() + str.slice(1);
    const removeFirstChar = (str) => str.slice(1);
    const caps = /[A-Z]/g;

    ownEnumerablePropertyNames.forEach((key) => {
      // if (propertiesInPrototypeChain[key]) {
      //   return;
      // }
      // determine attribute type
      const isNamedAttribute = key.indexOf('-') > -1;
      const isAliasAttribute = caps.test(key);
      // reduce key for computation
      const firstChar = key.charAt(0);
      const isPrefixedName = isNamedAttribute && firstChar == '-';
      const isCapitalizedAlias = isAliasAttribute && firstChar == firstChar.toUpperCase();
      key = isPrefixedName ? removeFirstChar(key) : isCapitalizedAlias ? uncapitalize(key) : key;
      // find counterpart in CSSStyleDeclaration object or its prototype chain
      if (isNamedAttribute) {
        const aliasAttribute = key
          .split('-')
          .map((word, index) => (index == 0 ? word : capitalize(word)))
          .join('');
        if (aliasAttribute in cssStyleDeclaration) {
          propertiesInPrototypeChain[aliasAttribute] = true;
        } else if (capitalize(aliasAttribute) in cssStyleDeclaration) {
          propertiesInPrototypeChain[capitalize(aliasAttribute)] = true;
        }
      } else if (isAliasAttribute) {
        const namedAttribute = key.replace(caps, (char) => '-' + char.toLowerCase());
        if (namedAttribute in cssStyleDeclaration) {
          propertiesInPrototypeChain[namedAttribute] = true;
        } else if (`-${namedAttribute}` in cssStyleDeclaration) {
          propertiesInPrototypeChain[`-${namedAttribute}`] = true;
        }
      }
      // return;
    });

    // compile keys
    const keys = [
      ...Array.from(
        new Set([
          ...prototypeProperties,
          ...ownEnumerablePropertyNames,
          ...Object.keys(propertiesInPrototypeChain),
        ])
      ),
    ];
    // @ts-ignore
    const interfaceName = ('' + proto).match(/\[object (.+)\]/)[1];

    return { keys, interfaceName };
  } catch (error) {
    return undefined;
  }
}

function computeStyle(type) {}
