import { isGecko, isWebKit } from '../utils/browser';
export async function getScreen() {
  try {
    const s = window.screen || {};
    const {
      width = 0,
      height = 0,
      availWidth = 0,
      availHeight = 0,
      colorDepth = 0,
      pixelDepth = 0,
    } = s as Screen;

    const dpr = window.devicePixelRatio || 0;
    const firefoxWithHighDPR = isGecko() && dpr != 1;
    let trust = true;

    if (!firefoxWithHighDPR) {
      // firefox with high dpr requires floating point precision dimensions
      const matchMediaLie = !matchMedia(
        `(device-width: ${width}px) and (device-height: ${height}px)`
      ).matches;
      if (matchMediaLie) {
        trust = false;
      }
    }

    const matchesDRP = matchMedia(`(resolution: ${dpr}dppx)`).matches;
    if (!isWebKit() && !matchesDRP) {
      trust = false;
    }

    const data = {
      width,
      height,
      availWidth,
      availHeight,
      colorDepth,
      pixelDepth,
      touch: hasTouch(),
      trust,
      hasTaskbar: !!(width - availWidth || height - availHeight),
    };

    return data;
  } catch (error) {
    return undefined;
  }
}

function hasTouch() {
  try {
    return 'ontouchstart' in window && !!document.createEvent('TouchEvent');
  } catch (err) {
    return false;
  }
}
