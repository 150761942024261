import { CSS_FONT_FAMILY, EMOJIS } from './constants';
import { hashSlice, formatEmojiSet } from './utils/helpers';
import { hashMini } from './utils/crypto';
import { patch, html } from './utils/html';

export async function getSVG(phantomWindow: Window) {
  try {
    const doc = phantomWindow?.document?.body ? phantomWindow.document : document;

    const divElement = document.createElement('div');
    doc.body.appendChild(divElement);

    // patch div
    patch(
      divElement,
      html`
        <div id="svg-container">
          <style>
            #svg-container {
              position: absolute;
              left: -9999px;
              height: auto;
            }
            #svg-container .shift-svg {
              transform: scale(1.000999) !important;
            }
            .svgrect-emoji {
              font-family: ${CSS_FONT_FAMILY};
              font-size: 200px !important;
              height: auto;
              position: absolute !important;
              transform: scale(1.000999);
            }
          </style>
          <svg>
            <g id="svgBox">
              ${EMOJIS.map((emoji) => {
                return `<text x="32" y="32" class="svgrect-emoji">${emoji}</text>`;
              }).join('')}
            </g>
          </svg>
        </div>
      `
    );

    // SVG
    const reduceToObject = (nativeObj) => {
      const keys = Object.keys(nativeObj.__proto__);
      return keys.reduce((acc, key) => {
        const val = nativeObj[key];
        const isMethod = typeof val == 'function';
        return isMethod ? acc : { ...acc, [key]: val };
      }, {});
    };
    const reduceToSum = (nativeObj) => {
      const keys = Object.keys(nativeObj.__proto__);
      return keys.reduce((acc, key) => {
        const val = nativeObj[key];
        return isNaN(val) ? acc : (acc += val);
      }, 0);
    };

    const getObjectSum = (obj) =>
      !obj ? 0 : Object.keys(obj).reduce((acc, key) => (acc += Math.abs(obj[key])), 0);

    // SVGRect
    const svgBox = doc.getElementById('svgBox') as unknown as SVGGElement;
    const bBox = reduceToObject(svgBox.getBBox());

    // compute SVGRect emojis
    const pattern = new Set();
    const svgElems: SVGTextContentElement[] = Array.from(
      svgBox.getElementsByClassName('svgrect-emoji') as HTMLCollectionOf<SVGTextContentElement>
    );

    const emojiSet = svgElems.reduce((emojiSet, el: SVGTextContentElement, i) => {
      const emoji = EMOJIS[i];
      const dimensions = '' + el.getComputedTextLength();
      if (!pattern.has(dimensions)) {
        pattern.add(dimensions);
        emojiSet.add(emoji);
      }
      return emojiSet;
    }, new Set());

    // svgRect System Sum
    const svgrectSystemSum =
      0.00001 *
      [...pattern]
        .map((x: string) => {
          return x.split(',').reduce((acc, x) => (acc += +x || 0), 0);
        })
        .reduce((acc, x) => (acc += x), 0);

    const data = {
      bBox: getObjectSum(bBox),
      extentOfChar: reduceToSum(svgElems[0].getExtentOfChar(Number(EMOJIS[0]))),
      subStringLength: svgElems[0].getSubStringLength(0, 10),
      computedTextLength: svgElems[0].getComputedTextLength(),
      emojiSet: [...emojiSet],
      svgrectSystemSum,
    };

    doc.body.removeChild(doc.getElementById('svg-container'));

    return data;
  } catch (error) {
    return undefined;
  }
}
