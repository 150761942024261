export const ATTRIBUTION_PARAM_MAP = {
  fx_matchtype: ['mt'],
  fx_network: ['n'],
  fx_creative: ['c'],
  fx_keyword: ['q'],
  fx_placement: ['p'],
  fx_aceid: ['ac'],
  fx_adposition: ['ap'],
  utm_campaign: ['utmc'],
  utm_content: ['utmct'],
  utm_source: ['utms', 's'],
  utm_medium: ['utmm', 'm'],
  utm_segment: ['utmse'],
  utm_term: ['utmt'],
  gclid: ['gclid'],
  gbraid: ['gbraid'],
  wbraid: ['wbraid'],
  msclkid: ['msclkid'],
  fbclid: ['fbclid'],
  twclid: ['twclid'],
  li_fat_id: ['li_fat_id'],
  epik: ['epik'],
  pp: ['pp'],
  adClickId: ['adId'],
};
