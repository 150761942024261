import { isBlink } from '../../utils/browser';
import { GIBBERS, PlatformClassifier } from '../constants';

export function getRandomValues() {
  return String.fromCharCode(Math.random() * 26 + 97) + Math.random().toString(36).slice(-7);
}

export const isGibberish = (str: string, { strict = false } = {}): string[] => {
  if (!str) return [];

  // test letter case sequence
  const letterCaseSequenceGibbers: string[] = [];
  const tests = [
    /([A-Z]{3,}[a-z])/g, // ABCd
    /([a-z][A-Z]{3,})/g, // aBCD
    /([a-z][A-Z]{2,}[a-z])/g, // aBC...z
    /([a-z][\d]{2,}[a-z])/g, // a##...b
    /([A-Z][\d]{2,}[a-z])/g, // A##...b
    /([a-z][\d]{2,}[A-Z])/g, // a##...B
  ];
  tests.forEach((regExp) => {
    const match = str.match(regExp);
    if (match) {
      return letterCaseSequenceGibbers.push(match.join(', '));
    }
    return;
  });

  // test letter sequence
  const letterSequenceGibbers: string[] = [];
  const clean = str
    .replace(/\d|\W|_/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .join('_');
  const len = clean.length;
  const arr = [...clean];

  arr.forEach((char, index) => {
    const nextIndex = index + 1;
    const nextChar = arr[nextIndex];
    const isWordSequence = nextChar !== '_' && char !== '_' && nextIndex !== len;

    if (isWordSequence) {
      const combo = char + nextChar;
      if (GIBBERS.test(combo)) letterSequenceGibbers.push(combo);
    }
  });

  const gibbers = [
    // ignore sequence if less than 3 exist
    ...(!strict && letterSequenceGibbers.length < 3 ? [] : letterSequenceGibbers),
    ...(!strict && letterCaseSequenceGibbers.length < 4 ? [] : letterCaseSequenceGibbers),
  ];

  const allow = [
    // known gibbers
    'bz',
    'cf',
    'fx',
    'mx',
    'vb',
    'xd',
    'gx',
    'PCIe',
    'vm',
    'NVIDIAGa',
  ];
  return gibbers.filter((x) => !allow.includes(x));
};

// export const IS_WORKER_SCOPE = !self.document && self.WorkerGlobalScope;

// Detect Browser
function getEngine() {
  const x = [].constructor;
  try {
    (-1).toFixed(-1);
  } catch (err) {
    return err.message.length + (x + '').split(x.name).join('').length;
  }
}

export function getReportedPlatform(userAgent: string, platform?: string): PlatformClassifier[] {
  // user agent os lie
  const userAgentOS =
    // order is important
    /win(dows|16|32|64|95|98|nt)|wow64/gi.test(userAgent)
      ? PlatformClassifier.WINDOWS
      : /android|linux|cros/gi.test(userAgent)
        ? PlatformClassifier.LINUX
        : /(i(os|p(ad|hone|od)))|mac/gi.test(userAgent)
          ? PlatformClassifier.APPLE
          : PlatformClassifier.OTHER;

  if (!platform) return [userAgentOS];

  const platformOS =
    // order is important
    /win/gi.test(platform)
      ? PlatformClassifier.WINDOWS
      : /android|arm|linux/gi.test(platform)
        ? PlatformClassifier.LINUX
        : /(i(os|p(ad|hone|od)))|mac/gi.test(platform)
          ? PlatformClassifier.APPLE
          : PlatformClassifier.OTHER;
  return [userAgentOS, platformOS];
}

// const { userAgent: navUserAgent, platform: navPlatform } = self.navigator || {};
// const [USER_AGENT_OS, PLATFORM_OS] = getReportedPlatform(navUserAgent, navPlatform);

export function getUserAgentPlatform({ userAgent, excludeBuild = true }) {
  if (!userAgent) {
    return 'unknown';
  }

  // patterns
  const nonPlatformParenthesis =
    /\((khtml|unlike|vizio|like gec|internal dummy|org\.eclipse|openssl|ipv6|via translate|safari|cardamon).+|xt\d+\)/gi;
  const parenthesis = /\((.+)\)/;
  const android = /((android).+)/i;
  const androidNoise =
    /^(linux|[a-z]|wv|mobile|[a-z]{2}(-|_)[a-z]{2}|[a-z]{2})$|windows|(rv:|trident|webview|iemobile).+/i;
  const androidBuild = /build\/.+\s|\sbuild\/.+/i;
  const androidRelease = /android( |-)\d+/i;
  const windows = /((windows).+)/i;
  const windowsNoise =
    /^(windows|ms(-|)office|microsoft|compatible|[a-z]|x64|[a-z]{2}(-|_)[a-z]{2}|[a-z]{2})$|(rv:|outlook|ms(-|)office|microsoft|trident|\.net|msie|httrack|media center|infopath|aol|opera|iemobile|webbrowser).+/i;
  const windows64bitCPU = /w(ow|in)64/i;
  const cros = /cros/i;
  const crosNoise = /^([a-z]|x11|[a-z]{2}(-|_)[a-z]{2}|[a-z]{2})$|(rv:|trident).+/i;
  const crosBuild = /\d+\.\d+\.\d+/i;
  const linux = /linux|x11|ubuntu|debian/i;
  const linuxNoise =
    /^([a-z]|x11|unknown|compatible|[a-z]{2}(-|_)[a-z]{2}|[a-z]{2})$|(rv:|java|oracle|\+http|http|unknown|mozilla|konqueror|valve).+/i;
  const apple = /(cpu iphone|cpu os|iphone os|mac os|macos|intel os|ppc mac).+/i;
  const appleNoise =
    /^([a-z]|macintosh|compatible|mimic|[a-z]{2}(-|_)[a-z]{2}|[a-z]{2}|rv|\d+\.\d+)$|(rv:|silk|valve).+/i;
  const appleRelease = /(ppc |intel |)(mac|mac |)os (x |x|)(\d{2}(_|\.)\d{1,2}|\d{2,})/i;
  const otherOS =
    /((symbianos|nokia|blackberry|morphos|mac).+)|\/linux|freebsd|symbos|series \d+|win\d+|unix|hp-ux|bsdi|bsd|x86_64/i;

  const isDevice = (list, device) => list.filter((x) => device.test(x)).length;

  userAgent = userAgent
    .trim()
    .replace(/\s{2,}/, ' ')
    .replace(nonPlatformParenthesis, '');

  if (parenthesis.test(userAgent)) {
    const platformSection = userAgent.match(parenthesis)[0];
    const identifiers = platformSection
      .slice(1, -1)
      .replace(/,/g, ';')
      .split(';')
      .map((x) => x.trim());

    if (isDevice(identifiers, android)) {
      return (
        identifiers
          // @ts-ignore
          .map((x) => (androidRelease.test(x) ? androidRelease.exec(x)[0].replace('-', ' ') : x))
          .filter((x) => !androidNoise.test(x))
          .join(' ')
          .replace(excludeBuild ? androidBuild : '', '')
          .trim()
          .replace(/\s{2,}/, ' ')
      );
    } else if (isDevice(identifiers, windows)) {
      return identifiers
        .filter((x) => !windowsNoise.test(x))
        .join(' ')
        .replace(/\sNT (\d+\.\d+)/, (match, version) => {
          return version == '10.0'
            ? ' 10'
            : version == '6.3'
              ? ' 8.1'
              : version == '6.2'
                ? ' 8'
                : version == '6.1'
                  ? ' 7'
                  : version == '6.0'
                    ? ' Vista'
                    : version == '5.2'
                      ? ' XP Pro'
                      : version == '5.1'
                        ? ' XP'
                        : version == '5.0'
                          ? ' 2000'
                          : version == '4.0'
                            ? match
                            : ' ' + version;
        })
        .replace(windows64bitCPU, '(64-bit)')
        .trim()
        .replace(/\s{2,}/, ' ');
    } else if (isDevice(identifiers, cros)) {
      return identifiers
        .filter((x) => !crosNoise.test(x))
        .join(' ')
        .replace(excludeBuild ? crosBuild : '', '')
        .trim()
        .replace(/\s{2,}/, ' ');
    } else if (isDevice(identifiers, linux)) {
      return identifiers
        .filter((x) => !linuxNoise.test(x))
        .join(' ')
        .trim()
        .replace(/\s{2,}/, ' ');
    } else if (isDevice(identifiers, apple)) {
      return identifiers
        .map((x) => {
          if (appleRelease.test(x)) {
            // @ts-ignore
            const release = appleRelease.exec(x)[0];
            const versionMap = {
              '10_7': 'Lion',
              '10_8': 'Mountain Lion',
              '10_9': 'Mavericks',
              '10_10': 'Yosemite',
              '10_11': 'El Capitan',
              '10_12': 'Sierra',
              '10_13': 'High Sierra',
              '10_14': 'Mojave',
              '10_15': 'Catalina',
              '11': 'Big Sur',
              '12': 'Monterey',
              '13': 'Ventura',
            };
            const version = ((/(\d{2}(_|\.)\d{1,2}|\d{2,})/.exec(release) || [])[0] || '').replace(
              /\./g,
              '_'
            );
            const isOSX = /^10/.test(version);
            const id = isOSX ? version : (/^\d{2,}/.exec(version) || [])[0];
            const codeName = versionMap[id];
            return codeName ? `macOS ${codeName}` : release;
          }
          return x;
        })
        .filter((x) => !appleNoise.test(x))
        .join(' ')
        .replace(/\slike mac.+/gi, '')
        .trim()
        .replace(/\s{2,}/, ' ');
    } else {
      const other = identifiers.filter((x) => otherOS.test(x));
      if (other.length) {
        return other
          .join(' ')
          .trim()
          .replace(/\s{2,}/, ' ');
      }
      return identifiers.join(' ');
    }
  } else {
    return 'unknown';
  }
}

export function computeWindowsRelease({ platform, platformVersion, fontPlatformVersion }) {
  if (platform != 'Windows' || !(isBlink() && CSS.supports('accent-color', 'initial'))) {
    return;
  }
  const platformVersionNumber = +(/(\d+)\./.exec(platformVersion) || [])[1];

  // https://github.com/WICG/ua-client-hints/issues/220#issuecomment-870858413
  // https://docs.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
  // https://docs.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-guidance
  const release: Record<string, string> = {
    '0.1.0': '7',
    '0.2.0': '8',
    '0.3.0': '8.1',
    '1.0.0': '10 (1507)',
    '2.0.0': '10 (1511)',
    '3.0.0': '10 (1607)',
    '4.0.0': '10 (1703)',
    '5.0.0': '10 (1709)',
    '6.0.0': '10 (1803)',
    '7.0.0': '10 (1809)',
    '8.0.0': '10 (1903|1909)',
    '10.0.0': '10 (2004|20H2|21H1)',
    '11.0.0': '10',
    '12.0.0': '10',
  };

  const oldFontPlatformVersionNumber = (/7|8\.1|8/.exec(fontPlatformVersion) || [])[0];
  const version =
    platformVersionNumber >= 13
      ? '11'
      : platformVersionNumber == 0 && oldFontPlatformVersionNumber
        ? oldFontPlatformVersionNumber
        : release[platformVersion] || 'Unknown';
  return `Windows ${version} [${platformVersion}]`;
}

// attempt windows 11 userAgent
export function attemptWindows11UserAgent({ userAgent, userAgentData, fontPlatformVersion }) {
  const { platformVersion, platform } = userAgentData || {};
  // @ts-ignore
  const windowsRelease = computeWindowsRelease({ platform, platformVersion });
  return /Windows 11/.test('' + windowsRelease) || /Windows 11/.test(fontPlatformVersion)
    ? ('' + userAgent).replace('Windows NT 10.0', 'Windows 11')
    : userAgent;
}

// attempt restore from User-Agent Reduction
export function isUAPostReduction(userAgent) {
  const matcher =
    /Mozilla\/5\.0 \((Macintosh; Intel Mac OS X 10_15_7|Windows NT 10\.0; Win64; x64|(X11; (CrOS|Linux) x86_64)|(Linux; Android 10(; K|)))\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/\d+\.0\.0\.0( Mobile|) Safari\/537\.36/;
  const unifiedPlatform = (matcher.exec(userAgent) || [])[1];
  return isBlink() && !!unifiedPlatform;
}

export async function getPromiseRaceFulfilled({ promise, responseType, limit = 1000 }) {
  const slowPromise = new Promise((resolve) => setTimeout(resolve, limit));
  const response = await Promise.race([slowPromise, promise])
    .then((response) => (response instanceof responseType ? response : 'pending'))
    .catch((error) => 'rejected');
  return response == 'rejected' || response == 'pending' ? undefined : response;
}

export function formatEmojiSet(emojiSet, limit = 3) {
  const maxLen = limit * 2 + 3;
  const list = emojiSet || [];
  return list.length > maxLen
    ? `${emojiSet.slice(0, limit).join('')}...${emojiSet.slice(-limit).join('')}`
    : list.join('');
}

export const hashSlice = (x) => (!x ? x : x.slice(0, 8));

export const attempt = (fn, customMessage = '') => {
  try {
    return fn();
  } catch (error) {
    return undefined;
  }
};

export const caniuse = (fn, objChainList = [], args = [], method = false) => {
  let api;
  try {
    api = fn();
  } catch (error) {
    return undefined;
  }
  let i;
  const len = objChainList.length;
  let chain = api;
  try {
    for (i = 0; i < len; i++) {
      const obj = objChainList[i];
      chain = chain[obj];
    }
  } catch (error) {
    return undefined;
  }
  return method && args.length
    ? chain.apply(api, args)
    : method && !args.length
      ? chain.apply(api)
      : chain;
};

// use if needed to stable fingerprint
export const LowerEntropy: Record<string, boolean> = {
  AUDIO: false,
  CANVAS: false,
  FONTS: false,
  SCREEN: false,
  TIME_ZONE: false,
  WEBGL: false,
};

export interface PhantomWindow {
  iframeWindow: Window;
  div?: HTMLDivElement | undefined;
}

const GHOST_STYLE = `
    height: 100vh;
    width: 100vw;
    position: absolute;
    left:-10000px;
    visibility: hidden;
  `;
export function createPhantomIframe(): PhantomWindow {
  try {
    const numberOfIframes = self.length;
    const frag = new DocumentFragment();
    const div = document.createElement('div');
    const id = getRandomValues();
    div.setAttribute('id', id);
    frag.appendChild(div);
    div.innerHTML = `<div style="${GHOST_STYLE}"><iframe></iframe></div>`;
    document.body.appendChild(frag);
    const iframeWindow = self[numberOfIframes];
    const phantomWindow = createEmbeddedIframe(iframeWindow);

    return { iframeWindow: phantomWindow || self, div };
  } catch (error) {
    return { iframeWindow: self };
  }
}

// const { iframeWindow: PHANTOM_DARKNESS, div: PARENT_PHANTOM } = getPhantomIframe() || {};

function createEmbeddedIframe(win: Window): Window | null {
  try {
    if (!isBlink()) return win;

    const div = win.document.createElement('div');
    div.setAttribute('id', getRandomValues());
    div.setAttribute('style', GHOST_STYLE);
    div.innerHTML = `<div><iframe></iframe></div>`;
    win.document.body.appendChild(div);

    const iframe = [...[...div.childNodes][0].childNodes][0] as HTMLIFrameElement;

    if (!iframe) return null;

    const { contentWindow } = iframe || {};
    if (!contentWindow) return null;

    const div2 = contentWindow.document.createElement('div');
    div2.innerHTML = `<div><iframe></iframe></div>`;
    contentWindow.document.body.appendChild(div2);
    const iframe2 = [...[...div2.childNodes][0].childNodes][0] as HTMLIFrameElement;
    return iframe2.contentWindow;
  } catch (error) {
    return win;
  }
}
