// template views
export function patch(oldEl: HTMLElement | null, newEl: DocumentFragment, fn?: () => any) {
  if (!oldEl) return null;
  oldEl.parentNode?.replaceChild(newEl, oldEl);
  return typeof fn === 'function' ? fn() : true;
}
export function html(templateStr: TemplateStringsArray, ...expressionSet: any[]) {
  const template = document.createElement('template');
  template.innerHTML = templateStr.map((s, i) => `${s}${expressionSet[i] || ''}`).join('');
  return document.importNode(template.content, true);
}

// template helpers
export const HTMLNote: Record<string, string> = {
  UNKNOWN: '<span class="blocked">unknown</span>',
  UNSUPPORTED: '<span class="blocked">unsupported</span>',
  BLOCKED: '<span class="blocked">blocked</span>',
  LIED: '<span class="lies">lied</span>',
  SECRET: '<span class="blocked">secret</span>',
};

export const pluralify = (len) => (len > 1 ? 's' : '');
export const count = (arr) => (arr && arr.constructor.name === 'Array' ? '' + arr.length : '0');

export const getDiffs = ({
  stringA,
  stringB,
  charDiff = false,
  decorate = (diff) => `[${diff}]`,
}) => {
  if (!stringA || !stringB) return;
  const splitter = charDiff ? '' : ' ';
  const listA = ('' + stringA).split(splitter);
  const listB = ('' + stringB).split(splitter);
  const listBWithDiffs = listB.map((x, i) => {
    const matcher = listA[i];
    const match = x == matcher;
    return !match ? decorate(x) : x;
  });
  return listBWithDiffs.join(splitter);
};

/**
 * Checks if the current window has any cross-origin parent frames in its ancestor chain.
 * This is done by attempting to access each parent window's origin and comparing it with
 * the current window's origin. If a SecurityError is thrown when accessing the parent's
 * location, it indicates a cross-origin frame.
 *
 * @returns {boolean} Returns true if a cross-origin parent is found, false if all ancestors
 *                    are same-origin or if the top-level window is reached.
 */
export function hasCrossOriginParent(): boolean {
  let currentWindow: Window = window;

  for (;;) {
    // infinite loop until reach top
    const parentWindow = currentWindow.parent;
    if (!parentWindow || parentWindow === currentWindow) {
      return false;
    }

    try {
      if (parentWindow.location.origin !== currentWindow.location.origin) {
        return true;
      }
    } catch (error) {
      if (error instanceof Error && error.name === 'SecurityError') {
        return true;
      }
    }

    currentWindow = parentWindow;
  }
}
