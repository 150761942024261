import { hashMini } from './utils/crypto';

export async function getWebRTC() {
  try {
    const [data, devices] = await Promise.all([getWebRTCData(), getWebRTCDevices()]);
    return {
      // data,
      devices,
    };
  } catch (error) {
    return undefined;
  }
}

/**
 * Collects WebRTC-related data for fingerprinting purposes.
 * Attempts to establish a WebRTC connection and gather information about:
 * - Supported codecs and their capabilities
 * - WebRTC extensions
 * - ICE candidate foundation
 * - IP address (if available)
 * - STUN connection details
 *
 * @returns Promise that resolves to a Record containing WebRTC data, or null if WebRTC is not supported
 * or if the connection attempt times out after 3 seconds
 */
export async function getWebRTCData(): Promise<Record<string, unknown> | null> {
  return new Promise(async (resolve) => {
    if (!window.RTCPeerConnection) {
      return resolve(null);
    }

    const config = {
      iceCandidatePoolSize: 1,
      iceServers: [
        {
          urls: ['stun:stun4.l.google.com:19302', 'stun:stun3.l.google.com:19302'],
        },
      ],
    };

    const connection = new RTCPeerConnection(config);
    connection.createDataChannel('');

    const options = { offerToReceiveAudio: 1, offerToReceiveVideo: 1 };

    const offer = await connection.createOffer(options as unknown as RTCOfferOptions);

    connection.setLocalDescription(offer);
    const { sdp } = offer || {};

    const extensions = getExtensions(sdp);
    const codecsSdp = getCapabilities(sdp);

    let iceCandidate = '';
    let foundation = '';
    const giveUpOnIPAddress = setTimeout(() => {
      connection.removeEventListener('icecandidate', computeCandidate);
      connection.close();
      if (sdp) {
        return resolve({
          codecsSdp,
          extensions,
          foundation,
          iceCandidate,
        });
      }
      return resolve(null);
    }, 3000);

    const computeCandidate = (event) => {
      const { candidate, foundation: foundationProp } = event.candidate || {};

      if (!candidate) {
        return;
      }

      if (!iceCandidate) {
        iceCandidate = candidate;
        foundation = (/^candidate:([\w]+)/.exec(candidate) || [])[1] || '';
      }

      const { sdp } = connection.localDescription || {};
      const address = getIPAddress(sdp);
      if (!address) {
        return;
      }

      const knownInterface: Record<string, string> = {
        842163049: 'public interface',
        2268587630: 'WireGuard',
      };

      connection.removeEventListener('icecandidate', computeCandidate);
      clearTimeout(giveUpOnIPAddress);
      connection.close();
      return resolve({
        codecsSdp,
        extensions,
        foundation: knownInterface[foundation] || foundation,
        foundationProp,
        iceCandidate,
        address,
        stunConnection: candidate,
      });
    };

    connection.addEventListener('icecandidate', computeCandidate);
  });
}

/**
 * Retrieves the list of media device kinds supported by the browser.
 *
 * @returns Promise that resolves to an array of media device kinds, or null if the browser does not support media devices
 */
export async function getWebRTCDevices(): Promise<MediaDeviceKind[] | null> {
  if (!navigator?.mediaDevices?.enumerateDevices) return null;
  return navigator.mediaDevices.enumerateDevices().then((devices) => {
    return devices.map((device) => device.kind).sort();
  });
}

/**
 * Constructs a media configuration object for decoding information.
 *
 * @param codec - The codec to configure
 * @param video - Video configuration
 * @param audio - Audio configuration
 * @returns MediaDecodingConfiguration object
 */
function getMediaConfig(codec, video, audio): MediaDecodingConfiguration {
  return {
    type: 'file',
    video: !/^video/.test(codec)
      ? undefined
      : {
          contentType: codec,
          ...video,
        },
    audio: !/^audio/.test(codec)
      ? undefined
      : {
          contentType: codec,
          ...audio,
        },
  };
}

/**
 * Retrieves the media capabilities of the browser for a given set of codecs.
 *
 * @returns Promise that resolves to a Record containing media capabilities, or null if an error occurs
 */
export const getMediaCapabilities = async () => {
  const video = {
    width: 1920,
    height: 1080,
    bitrate: 120000,
    framerate: 60,
  };

  const audio = {
    channels: 2,
    bitrate: 300000,
    samplerate: 5200,
  };

  const codecs = [
    'audio/ogg; codecs=vorbis',
    'audio/ogg; codecs=flac',
    'audio/mp4; codecs="mp4a.40.2"',
    'audio/mpeg; codecs="mp3"',
    'video/ogg; codecs="theora"',
    'video/mp4; codecs="avc1.42E01E"',
  ];

  const decodingInfo = codecs.map((codec) => {
    const config = getMediaConfig(codec, video, audio);
    // @ts-ignore
    return navigator.mediaCapabilities
      .decodingInfo(config)
      .then((support) => ({
        codec,
        ...support,
      }))
      .catch((error) => console.error(codec, error));
  });

  const capabilities = await Promise.all(decodingInfo)
    .then((data) => {
      return data.reduce((acc, support) => {
        const { codec, supported, smooth, powerEfficient } = support || {};
        if (!supported) return acc;
        return {
          ...acc,
          ['' + codec]: [...(smooth ? ['smooth'] : []), ...(powerEfficient ? ['efficient'] : [])],
        };
      }, {});
    })
    .catch((error) => console.error(error));

  return capabilities;
};

/**
 * Extracts WebRTC extensions from an SDP string.
 *
 * @param sdp - The SDP string to extract extensions from
 * @returns Array of unique extensions sorted alphabetically
 */
function getExtensions(sdp) {
  const extensions = (('' + sdp).match(/extmap:\d+ [^\n|\r]+/g) || []).map((x) =>
    x.replace(/extmap:[^\s]+ /, '')
  );
  return [...new Set(extensions)].sort();
}

/**
 * Creates a counter object for tracking RTX codec occurrences.
 *
 * @returns Object with increment and getValue methods
 */
function createCounter() {
  let counter = 0;
  return {
    increment: () => (counter += 1),
    getValue: () => counter,
  };
}

/**
 * Constructs descriptions of media codecs from an SDP string.
 * https://webrtchacks.com/sdp-anatomy/
 * https://tools.ietf.org/id/draft-ietf-rtcweb-sdp-08.html
 *
 * @param mediaType - The type of media (audio or video)
 * @param sdp - The SDP string to extract descriptions from
 * @param sdpDescriptors - Array of descriptors to extract descriptions for
 * @param rtxCounter - Counter object for RTX codecs
 * @returns Array of media codec descriptions
 */
function constructDescriptions({ mediaType, sdp, sdpDescriptors, rtxCounter }) {
  if (!('' + sdpDescriptors)) {
    return;
  }
  return sdpDescriptors.reduce((descriptionAcc, descriptor) => {
    const matcher = `(rtpmap|fmtp|rtcp-fb):${descriptor} (.+)`;
    const formats = sdp.match(new RegExp(matcher, 'g')) || [];
    if (!('' + formats)) {
      return descriptionAcc;
    }
    const isRtxCodec = ('' + formats).includes(' rtx/');
    if (isRtxCodec) {
      if (rtxCounter.getValue()) {
        return descriptionAcc;
      }
      rtxCounter.increment();
    }
    const getLineData = (x) => x.replace(/[^\s]+ /, '');
    const description = formats.reduce((acc, x) => {
      const rawData = getLineData(x);
      const data = rawData.split('/');
      const codec = data[0];
      const description: Record<string, any> = {};

      if (x.includes('rtpmap')) {
        if (mediaType == 'audio') {
          description.channels = +data[2] || 1;
        }
        description.mimeType = `${mediaType}/${codec}`;
        description.clockRates = [+data[1]];
        return {
          ...acc,
          ...description,
        };
      } else if (x.includes('rtcp-fb')) {
        return {
          ...acc,
          feedbackSupport: [...(acc.feedbackSupport || []), rawData],
        };
      } else if (isRtxCodec) {
        return acc; // no sdpFmtpLine
      }
      return { ...acc, sdpFmtpLine: [...rawData.split(';')] };
    }, {});

    let shouldMerge = false;
    const mergerAcc = descriptionAcc.map((x) => {
      shouldMerge = x.mimeType == description.mimeType;
      if (shouldMerge) {
        if (x.feedbackSupport) {
          x.feedbackSupport = [...new Set([...x.feedbackSupport, ...description.feedbackSupport])];
        }
        if (x.sdpFmtpLine) {
          x.sdpFmtpLine = [...new Set([...x.sdpFmtpLine, ...description.sdpFmtpLine])];
        }
        return {
          ...x,
          clockRates: [...new Set([...x.clockRates, ...description.clockRates])],
        };
      }
      return x;
    });
    if (shouldMerge) {
      return mergerAcc;
    }
    return [...descriptionAcc, description];
  }, []);
}

/**
 * Extracts and constructs media capabilities from an SDP string.
 *
 * @param sdp - The SDP string to extract capabilities from
 * @returns Object containing audio and video capabilities
 */
function getCapabilities(sdp) {
  const videoDescriptors = ((/m=video [^\s]+ [^\s]+ ([^\n|\r]+)/.exec(sdp) || [])[1] || '').split(
    ' '
  );
  const audioDescriptors = ((/m=audio [^\s]+ [^\s]+ ([^\n|\r]+)/.exec(sdp) || [])[1] || '').split(
    ' '
  );
  const rtxCounter = createCounter();
  return {
    audio: constructDescriptions({
      mediaType: 'audio',
      sdp,
      sdpDescriptors: audioDescriptors,
      rtxCounter,
    }),
    video: constructDescriptions({
      mediaType: 'video',
      sdp,
      sdpDescriptors: videoDescriptors,
      rtxCounter,
    }),
  };
}

/**
 * Extracts the IP address from an SDP string.
 *
 * @param sdp - The SDP string to extract the IP address from
 * @returns The IP address, or undefined if it is blocked or not found
 */
function getIPAddress(sdp) {
  const blocked = '0.0.0.0';
  const candidateEncoding = /((udp|tcp)\s)([\d\w]+\s)(([\d\w]|(\.|\:))+)(?=\s)/gi;
  const connectionLineEncoding = /(c=IN\s)(.+)\s/gi;
  const connectionLineIpAddress = ((sdp.match(connectionLineEncoding) || [])[0] || '')
    .trim()
    .split(' ')[2];
  if (connectionLineIpAddress && connectionLineIpAddress != blocked) {
    return connectionLineIpAddress;
  }
  const candidateIpAddress = ((sdp.match(candidateEncoding) || [])[0] || '').split(' ')[2];
  return candidateIpAddress && candidateIpAddress != blocked ? candidateIpAddress : undefined;
}
