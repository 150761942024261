import { isBrave, getBraveMode, type BraveMode } from '../utils/browser';
import { caniuse } from './utils/helpers';

export function getCSSMedia(phantomWindow) {
  try {
    const win = phantomWindow.window;

    const { body } = win.document;
    const { width, availWidth, height, availHeight } = win.screen;

    const noTaskbar = !(width - availWidth || height - availHeight);
    const deviceAspectRatio = getAspectRatio(width, height);
    const matchMediaCSS = getMatchMedia({ win, width, height, deviceAspectRatio });
    const mediaCSS = getMediaCSS({ body, deviceAspectRatio, width, height });
    const screenQuery = getScreenMedia({ body, width, height });

    const braveMode = isBrave() ? getBraveMode() : ({} as BraveMode);
    const braveFingerprintingBlocking = isBrave() && (braveMode.standard || braveMode.strict);

    return {
      reducedMotion: caniuse(() => mediaCSS['prefers-reduced-motion']),
      colorScheme: braveFingerprintingBlocking
        ? undefined
        : caniuse(() => mediaCSS['prefers-color-scheme']),
      monochrome: caniuse(() => mediaCSS.monochrome),
      invertedColors: caniuse(() => mediaCSS['inverted-colors']),
      forcedColors: caniuse(() => mediaCSS['forced-colors']),
      anyHover: caniuse(() => mediaCSS['any-hover']),
      hover: caniuse(() => mediaCSS.hover),
      anyPointer: caniuse(() => mediaCSS['any-pointer']),
      pointer: caniuse(() => mediaCSS.pointer),
      colorGamut: caniuse(() => mediaCSS['color-gamut']),
      screenQuery: screenQuery, // flaky
      noTaskbar,
    };
  } catch (error) {
    return undefined;
  }
}

/**
 * get the screen media
 * @param {Object} params - The parameters object
 * @param {HTMLElement} params.body - The body element
 * @param {number} params.width - The device/screen width in pixels
 * @param {number} params.height - The device/screen height in pixels
 * @returns {Object} An object containing the width and height of the device/screen
 */
const getScreenMedia = ({ body, width, height }) => {
  let widthMatch = query({ body, type: 'width', rangeStart: width, rangeLen: 1 });
  let heightMatch = query({ body, type: 'height', rangeStart: height, rangeLen: 1 });
  if (widthMatch && heightMatch) {
    return { width, height };
  }
  const rangeLen = 1000;
  [...Array(10)].find((slot, i) => {
    if (!widthMatch) {
      widthMatch = query({ body, type: 'width', rangeStart: i * rangeLen, rangeLen });
    }
    if (!heightMatch) {
      heightMatch = query({ body, type: 'height', rangeStart: i * rangeLen, rangeLen });
    }
    return widthMatch && heightMatch;
  });
  return { width: +widthMatch, height: +heightMatch };
};

/**
 * get the media CSS
 * @param {Object} params - The parameters object
 * @param {HTMLElement} params.body - The body element
 * @param {string} params.deviceAspectRatio - The device aspect ratio in format "width/height"
 * @param {number} params.width - The device/screen width in pixels
 * @param {number} params.height - The device/screen height in pixels
 * @returns {Object} An object containing the media CSS
 */
function getMediaCSS({ body, deviceAspectRatio, width, height }) {
  try {
    body.innerHTML = `
		<style>
		@media (prefers-reduced-motion: no-preference) {body {--prefers-reduced-motion: no-preference}}
		@media (prefers-reduced-motion: reduce) {body {--prefers-reduced-motion: reduce}}
		@media (prefers-color-scheme: light) {body {--prefers-color-scheme: light}}
		@media (prefers-color-scheme: dark) {body {--prefers-color-scheme: dark}}
		@media (monochrome) {body {--monochrome: monochrome}}
		@media (monochrome: 0) {body {--monochrome: non-monochrome}}
		@media (inverted-colors: inverted) {body {--inverted-colors: inverted}}
		@media (inverted-colors: none) {body {--inverted-colors: none}}
		@media (forced-colors: none) {body {--forced-colors: none}}
		@media (forced-colors: active) {body {--forced-colors: active}}
		@media (any-hover: hover) {body {--any-hover: hover}}
		@media (any-hover: none) {body {--any-hover: none}}
		@media (hover: hover) {body {--hover: hover}}
		@media (hover: none) {body {--hover: none}}
		@media (any-pointer: fine) {body {--any-pointer: fine}}
		@media (any-pointer: coarse) {body {--any-pointer: coarse}}
		@media (any-pointer: none) {body {--any-pointer: none}}
		@media (pointer: fine) {body {--pointer: fine}}
		@media (pointer: coarse) {body {--pointer: coarse}}
		@media (pointer: none) {body {--pointer: none}}
		@media (device-aspect-ratio: ${deviceAspectRatio}) {body {--device-aspect-ratio: ${deviceAspectRatio}}}
		@media (device-width: ${width}px) and (device-height: ${height}px) {body {--device-screen: ${width} x ${height}}}
		@media (display-mode: fullscreen) {body {--display-mode: fullscreen}}
		@media (display-mode: standalone) {body {--display-mode: standalone}}
		@media (display-mode: minimal-ui) {body {--display-mode: minimal-ui}}
		@media (display-mode: browser) {body {--display-mode: browser}}
		@media (color-gamut: srgb) {body {--color-gamut: srgb}}
		@media (color-gamut: p3) {body {--color-gamut: p3}}
		@media (color-gamut: rec2020) {body {--color-gamut: rec2020}}
		@media (orientation: landscape) {body {--orientation: landscape}}
		@media (orientation: portrait) {body {--orientation: portrait}}
		</style>
		`;

    const style = getComputedStyle(body);
    return {
      ['prefers-reduced-motion']:
        style.getPropertyValue('--prefers-reduced-motion').trim() || undefined,
      ['prefers-color-scheme']:
        style.getPropertyValue('--prefers-color-scheme').trim() || undefined,
      monochrome: style.getPropertyValue('--monochrome').trim() || undefined,
      ['inverted-colors']: style.getPropertyValue('--inverted-colors').trim() || undefined,
      ['forced-colors']: style.getPropertyValue('--forced-colors').trim() || undefined,
      ['any-hover']: style.getPropertyValue('--any-hover').trim() || undefined,
      hover: style.getPropertyValue('--hover').trim() || undefined,
      ['any-pointer']: style.getPropertyValue('--any-pointer').trim() || undefined,
      pointer: style.getPropertyValue('--pointer').trim() || undefined,
      ['device-aspect-ratio']: style.getPropertyValue('--device-aspect-ratio').trim() || undefined,
      ['device-screen']: style.getPropertyValue('--device-screen').trim() || undefined,
      ['display-mode']: style.getPropertyValue('--display-mode').trim() || undefined,
      ['color-gamut']: style.getPropertyValue('--color-gamut').trim() || undefined,
      orientation: style.getPropertyValue('--orientation').trim() || undefined,
    };
  } catch (error) {
    return undefined;
  }
}

/**
 * Detects various CSS media features of the browser/device environment.
 *
 * @param {Object} params - The parameters object
 * @param {Window} params.win - The window object
 * @param {number} params.width - The device/screen width in pixels
 * @param {number} params.height - The device/screen height in pixels
 * @param {string} params.deviceAspectRatio - The device aspect ratio in format "width/height"
 *
 * @returns {Object} An object containing the following media query results:
 *   - prefers-reduced-motion: User's motion preference ('no-preference' | 'reduce')
 *   - prefers-color-scheme: User's color scheme preference ('light' | 'dark')
 *   - monochrome: Whether device has monochrome display ('monochrome' | 'non-monochrome')
 *   - inverted-colors: Color inversion status ('inverted' | 'none')
 *   - forced-colors: Forced color status ('none' | 'active')
 *   - any-hover: Device hover capability ('hover' | 'none')
 *   - hover: Primary input hover capability ('hover' | 'none')
 *   - any-pointer: Available pointer precision ('fine' | 'coarse' | 'none')
 *   - pointer: Primary pointer precision ('fine' | 'coarse' | 'none')
 *   - device-aspect-ratio: The device aspect ratio as string (e.g., "16/9")
 *   - device-screen: Device dimensions as string (e.g., "1920 x 1080")
 *   - display-mode: Display mode ('fullscreen' | 'standalone' | 'minimal-ui' | 'browser')
 *   - color-gamut: Color gamut support ('srgb' | 'p3' | 'rec2020')
 *   - orientation: Device orientation ('landscape' | 'portrait')
 */
export function getMatchMedia({ win, width, height, deviceAspectRatio }) {
  return {
    ['prefers-reduced-motion']: getMediaValue(win, [
      ['(prefers-reduced-motion: no-preference)', 'no-preference'],
      ['(prefers-reduced-motion: reduce)', 'reduce'],
    ]),

    ['prefers-color-scheme']: getMediaValue(win, [
      ['(prefers-color-scheme: light)', 'light'],
      ['(prefers-color-scheme: dark)', 'dark'],
    ]),

    monochrome: getMediaValue(win, [
      ['(monochrome)', 'monochrome'],
      ['(monochrome: 0)', 'non-monochrome'],
    ]),

    ['inverted-colors']: getMediaValue(win, [
      ['(inverted-colors: inverted)', 'inverted'],
      ['(inverted-colors: none)', 'none'],
    ]),

    ['forced-colors']: getMediaValue(win, [
      ['(forced-colors: none)', 'none'],
      ['(forced-colors: active)', 'active'],
    ]),

    ['any-hover']: getMediaValue(win, [
      ['(any-hover: hover)', 'hover'],
      ['(any-hover: none)', 'none'],
    ]),

    hover: getMediaValue(win, [
      ['(hover: hover)', 'hover'],
      ['(hover: none)', 'none'],
    ]),

    ['any-pointer']: getMediaValue(win, [
      ['(any-pointer: fine)', 'fine'],
      ['(any-pointer: coarse)', 'coarse'],
      ['(any-pointer: none)', 'none'],
    ]),

    pointer: getMediaValue(win, [
      ['(pointer: fine)', 'fine'],
      ['(pointer: coarse)', 'coarse'],
      ['(pointer: none)', 'none'],
    ]),

    ['device-aspect-ratio']: getMediaValue(win, [
      [`(device-aspect-ratio: ${deviceAspectRatio})`, deviceAspectRatio],
    ]),

    ['device-screen']: getMediaValue(win, [
      [`(device-width: ${width}px) and (device-height: ${height}px)`, `${width} x ${height}`],
    ]),

    ['display-mode']: getMediaValue(win, [
      ['(display-mode: fullscreen)', 'fullscreen'],
      ['(display-mode: standalone)', 'standalone'],
      ['(display-mode: minimal-ui)', 'minimal-ui'],
      ['(display-mode: browser)', 'browser'],
    ]),

    ['color-gamut']: getMediaValue(win, [
      ['(color-gamut: srgb)', 'srgb'],
      ['(color-gamut: p3)', 'p3'],
      ['(color-gamut: rec2020)', 'rec2020'],
    ]),

    orientation: getMediaValue(win, [
      ['(orientation: landscape)', 'landscape'],
      ['(orientation: portrait)', 'portrait'],
    ]),
  };
}

// Helper function to get value from multiple possible queries
function getMediaValue<T>(win: Window, queries: Array<[string, T]>): T | undefined {
  for (const [query, value] of queries) {
    if (win.matchMedia(query).matches) {
      return value;
    }
  }
  return undefined;
}

/**
 * greatest common divisor. implements the Euclidean algorithm to find the
 * largest number that divides both input numbers (a and b) without leaving a remainder
 * @param a number
 * @param b number
 * @returns number
 */
const gcd = (a, b) => (b == 0 ? a : gcd(b, a % b));

/**
 * get the aspect ratio of the device/screen
 * @param width number
 * @param height number
 * @returns string
 */
function getAspectRatio(width, height) {
  const r = gcd(width, height);
  const aspectRatio = `${width / r}/${height / r}`;
  return aspectRatio;
}

/**
 * query the device/screen media
 * @param {Object} params - The parameters object
 * @param {HTMLElement} params.body - The body element
 * @param {string} params.type - The type of media query
 * @param {number} params.rangeStart - The start of the range
 * @param {number} params.rangeLen - The length of the range
 * @returns string
 */
function query({ body, type, rangeStart, rangeLen }) {
  const html = [...Array(rangeLen)]
    .map((slot, i) => {
      i += rangeStart;
      return `@media(device-${type}:${i}px){body{--device-${type}:${i};}}`;
    })
    .join('');
  body.innerHTML = `<style>${html}</style>`;
  const style = getComputedStyle(body);
  return style.getPropertyValue(`--device-${type}`).trim();
}

/**
 * get the screen size
 *
 * @param {Object} params - The parameters object
 * @param {HTMLElement} params.body - The body element
 * @param {number} params.width - The device/screen width in pixels
 * @param {number} params.height - The device/screen height in pixels
 *
 * @returns {Object} An object containing the width and height of the device/screen
 */
function getScreenSize({ body, width, height }): { width: number; height: number } {
  let widthMatch = query({ body, type: 'width', rangeStart: width, rangeLen: 1 });
  let heightMatch = query({ body, type: 'height', rangeStart: height, rangeLen: 1 });

  if (widthMatch && heightMatch) {
    return { width, height };
  }

  const rangeLen = 1000;
  [...Array(10)].find((slot, i) => {
    if (!widthMatch) {
      widthMatch = query({ body, type: 'width', rangeStart: i * rangeLen, rangeLen });
    }
    if (!heightMatch) {
      heightMatch = query({ body, type: 'height', rangeStart: i * rangeLen, rangeLen });
    }
    return widthMatch && heightMatch;
  });

  return { width: +widthMatch, height: +heightMatch };
}
