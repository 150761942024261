import { request } from '../utils/request';
import type { MCFXTracker } from '../declarations';

type ServerInfo = {
  'caddy-ja3-fingerprint': string;
  'caddy-ja3-fingerprint-hash': string;
  'caddy-ja4-fingerprint': string;
  'caddy-ja4-fingerprint-hash': string;
  'caddy-http2-fingerprint': string;
  'caddy-http2-fingerprint-hash': string;
  'caddy-country': string;
  'caddy-region': string;
  'caddy-city': string;
  'caddy-city-lat': string;
  'caddy-city-lon': string;
};

export async function getServer(tracker: MCFXTracker) {
  try {
    const url = tracker.configuration.agentUrl.includes('.marketingcloudfx.com')
      ? 'https://fp.marketingcloudfx.com'
      : tracker.configuration.agentUrl;

    const info = (await request(`${url}/_info`).get({ timeout: 1500 })) as ServerInfo;

    return {
      ja3: info['caddy-ja3-fingerprint-hash'],
      ja4: info['caddy-ja4-fingerprint-hash'],
      http2: info['caddy-http2-fingerprint-hash'],
      country: info['caddy-country'],
      region: info['caddy-region'],
      city: info['caddy-city'],
      coordinates: `${info['caddy-city-lat']},${info['caddy-city-lon']}`,
      tracker,
    };
  } catch (error) {
    return {};
  }
}
