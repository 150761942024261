import { isFunction, isPlainObject } from './utils/helpers';
import { Tracker } from './tracker';
import type { McfxConfigOptions } from './declarations';
// monkey patch history to track all history events
import './utils/history';

// grabs any queued queries
const { q: queuedActions, e: actualEnteredTime, u: agentUrl } = window['mcfx'] || {};
window['mcfx'] = function (...args) {
  const [actionToPerform, ...passThroughArgs] = args;

  if (!this.tracker && actionToPerform === 'create') {
    this.tracker = new Tracker(
      {
        ...(agentUrl && { agentUrl }),
        ...((isPlainObject(args[1]) ? args[1] : { siteId: args[1] }) as McfxConfigOptions),
      },
      actualEnteredTime
    );
    return;
  }

  if (this.tracker && actionToPerform === 'destroy') {
    this.tracker.disableModules(this.tracker.configuration.modules);
    this.tracker = undefined;
    return;
  }

  if (!this.tracker) {
    throw new Error(
      'MarketingCloudFX Tracker has not been configured. Please call create before other methods.'
    );
  }

  /**
   * If the action to perform is a function,
   * then we will pass it the tracker instance and carry any other arguments
   *
   * this lets perform controlled actions with the tracker or get data from it
   *
   * @example
   * mcfx((tracker) => { console.log(tracker.options) }))
   * mcfx('get', 'm');
   */
  if (isFunction(actionToPerform)) {
    actionToPerform(this.tracker, ...passThroughArgs);
    return;
  }

  /**
   * If the action to perform is a name of a method,
   * then we will pass it the tracker instance and carry any other arguments
   *
   * this lets perform controlled actions with the tracker or get data from it
   *
   * @example
   * mcfx('get', 'm');
   */
  if (this.tracker[actionToPerform] && isFunction(this.tracker[actionToPerform])) {
    this.tracker[actionToPerform](...passThroughArgs);
  }
};

if (queuedActions) {
  queuedActions.forEach((args) => window['mcfx'](...args));
}
