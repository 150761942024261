export function getTimezone(): string {
  const DateTimeFormat = window.Intl?.DateTimeFormat;

  if (DateTimeFormat) {
    const timezone = new DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      return timezone;
    }
  }

  return `UTC${getTimezoneOffset()}`;
}

function getTimezoneOffset(): number {
  const currentYear = new Date().getFullYear();

  // (DST) shifts offset so we calculate the max of both January and July offsets
  // 60 Convert minutes to hours and invert sign
  return Math.max(
    -new Date(currentYear, 0, 1).getTimezoneOffset() / 60,
    -new Date(currentYear, 6, 1).getTimezoneOffset() / 60
  );
}
