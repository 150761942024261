import { isWebKit, isWebKit616OrNewer, isSafariWebKit, isFirefox } from '../utils/browser';

/**
 * Measures browser stack characteristics by comparing recursive function calls with and without local variables.
 * Used for browser fingerprinting by detecting differences in JavaScript engine implementations.
 *
 * @returns {string} A colon-separated string containing:
 *   - sizeA: Number of recursive calls possible with no local variables
 *   - sizeB: Number of recursive calls possible with a local variable
 *   - bytes: Estimated bytes per stack frame (sizeB * 8 / (sizeA - sizeB))
 * @returns {undefined} When running on Safari 17+ (which randomizes stack sizes)
 *
 * @example
 * const stackInfo = getStackBytes();
 * // Returns something like "12000:10000:32"
 */
export function getStackBytes(): string {
  // not supported or Safari 17+ or Firefox
  // returns random values as part of anti-fingerprinting
  if ((isWebKit() && isWebKit616OrNewer() && isSafariWebKit()) || isFirefox()) {
    return '0:0:0';
  }

  //

  let sizeA = 0;
  let sizeB = 0;
  let counter = 0;

  try {
    const fn = () => {
      counter += 1;
      fn();
    };
    fn();
  } catch {
    sizeA = counter;
    try {
      counter = 0;
      const fn = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const local = 1;
        counter += 1;
        fn();
      };
      fn();
    } catch {
      sizeB = counter;
    }
  }

  const bytes = (sizeB * 8) / (sizeA - sizeB);
  return [sizeA, sizeB, bytes].join(':');
}
