import { isBlink } from '../utils/browser';

export async function getVoices() {
  // Don't run voice immediately. This is unstable
  // wait a bit for services to load
  await new Promise((resolve) => setTimeout(() => resolve(undefined), 50));
  return new Promise(async (resolve) => {
    try {
      // use window since iframe is unstable in FF
      const supported = 'speechSynthesis' in window;
      supported && speechSynthesis.getVoices(); // warm up
      if (!supported) {
        return resolve(null);
      }

      const giveUpOnVoices = setTimeout(() => {
        return resolve(undefined);
      }, 300);

      const getVoices = () => {
        const voices = speechSynthesis.getVoices();
        const localServiceDidLoad = (voices || []).find((x) => x.localService);
        if (!voices || !voices.length || (isBlink() && !localServiceDidLoad)) {
          return;
        }
        clearTimeout(giveUpOnVoices);

        return resolve({
          voices: voices.map((v) => `${v.name} (${v.lang})`),
          languages: Array.from(new Set(voices.map((x) => x.lang))),
          defaultVoice:
            voices.filter((v) => v.default).map((v) => `${v.name} (${v.lang})`)[0] ?? null,
        });
      };

      getVoices();
      if (speechSynthesis.addEventListener) {
        return speechSynthesis.addEventListener('voiceschanged', getVoices);
      }
      speechSynthesis.onvoiceschanged = getVoices;
    } catch (error) {
      return resolve(null);
    }
  });
}
