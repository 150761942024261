/**
 * Returns the field type of any inputs within a form.

 * @param {Element<Form>} form the form element to get values from
 * @returns Object
 */
export function getFieldTypes(form: HTMLFormElement) {
  if (!form) {
    throw new Error('You must provide a form.');
  }

  return Array.from(form.elements || []).reduce((fields, el) => {
    const type = `${(el as HTMLInputElement).type}`.toLowerCase();

    if (['submit', 'reset', 'button'].includes(type)) {
      return fields;
    }

    const key = (el as HTMLInputElement).name
      ? (el as HTMLInputElement).name.toLowerCase()
      : el.getAttribute('id')?.toLowerCase();

    if (key) {
      fields[key] = type;
    }

    return fields;
  }, {});
}
