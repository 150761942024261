// https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
const windowBeacon = navigator.sendBeacon.bind(navigator);

type Options = RequestInit & {
  timeout?: number;
};

export function request<T = unknown>(url) {
  return {
    get: async (options: Options = {}): Promise<T> => {
      const controller = new AbortController();
      const { timeout, ...restOptions } = options;

      if (timeout) {
        setTimeout(() => controller.abort(), timeout);
      }

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
        ...restOptions,
      });
      return await response.json();
    },
    post: async (data, options: Options = {}): Promise<T> => {
      const controller = new AbortController();
      const { timeout, ...restOptions } = options;

      if (timeout) {
        setTimeout(() => controller.abort(), timeout);
      }

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
        ...restOptions,
      });
      return await response.json();
    },
  };
}

export function beacon(url: string, data: any) {
  if (!navigator.sendBeacon) {
    request(url).post(data);
    return;
  }
  try {
    const queued = windowBeacon(
      url.replace(
        /(^\w+:|^)\/\//,
        'https:' === document.location.protocol ? 'https://' : 'http://'
      ),
      typeof data !== 'string' ? JSON.stringify(data) : data
    );
    if (!queued) {
      throw new Error('Failed to queue beacon');
    }
  } catch (err) {
    request(url).post(data);
  }
}
