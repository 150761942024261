import {
  isWebKit,
  isDesktopWebKit,
  isWebKit606OrNewer,
  isWebKit616OrNewer,
  isSafariWebKit,
  isChromium,
  isSamsungInternet,
  isChromium122OrNewer,
} from '../utils/browser';
import { isPromise, suppressUnhandledRejection } from '../utils/helpers';
// import { isPromise, suppressUnhandledRejection } from '../utils/async';

const enum InnerErrorName {
  Timeout = 'timeout',
  Suspended = 'suspended',
}

/**
 * https://fingerprint.com/blog/audio-fingerprinting/
 * https://github.com/cozylife/audio-fingerprint
 *
 * A version of the entropy source with stabilization to make it suitable for static fingerprinting.
 * Audio signal is noised in private mode of Safari 17, so audio fingerprinting is skipped in Safari 17.
 */
export function getAudio(): number | Promise<number> {
  if (doesBrowserPerformAntifingerprinting()) {
    return 0;
  }

  return getUnstableAudioFingerprint();
}

/**
 * A version of the entropy source without stabilization.
 *
 * Warning for package users:
 * This function is out of Semantic Versioning, i.e. can change unexpectedly. Usage is at your own risk.
 */
export function getUnstableAudioFingerprint(): number | Promise<number> {
  // @ts-expect-error OfflineAudioContext is not supported in Safari
  const AudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
  if (!AudioContext) {
    return -1;
  }

  // In some browsers, audio context always stays suspended unless the context is started in response to a user action
  // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
  if (doesBrowserSuspendAudioContext()) {
    return -2;
  }

  const hashFromIndex = 4500;
  const hashToIndex = 5000;
  const context = new AudioContext(1, hashToIndex, 44100);

  const oscillator = context.createOscillator();
  oscillator.type = 'triangle';
  oscillator.frequency.value = 10000;

  const compressor = context.createDynamicsCompressor();
  compressor.threshold.value = -50;
  compressor.knee.value = 40;
  compressor.ratio.value = 12;
  compressor.attack.value = 0;
  compressor.release.value = 0.25;

  oscillator.connect(compressor);
  compressor.connect(context.destination);
  oscillator.start(0);

  const [renderPromise, finishRendering] = startRenderingAudio(context);
  // Suppresses the console error message in case when the fingerprint fails before requested
  const fingerprintPromise = suppressUnhandledRejection(
    renderPromise.then(
      (buffer) => getHash(buffer.getChannelData(0).subarray(hashFromIndex)),
      (error) => {
        if (error.name === InnerErrorName.Timeout || error.name === InnerErrorName.Suspended) {
          return 0;
        }
        throw error;
      }
    )
  );

  finishRendering();
  return fingerprintPromise;
}

/**
 * Checks if the current browser is known for always suspending audio context
 */
function doesBrowserSuspendAudioContext() {
  return isWebKit() && !isDesktopWebKit() && !isWebKit606OrNewer();
}

/**
 * Checks if the current browser is known for applying anti-fingerprinting measures in all or some critical modes
 */
function doesBrowserPerformAntifingerprinting() {
  return (
    (isWebKit() && isWebKit616OrNewer() && isSafariWebKit()) ||
    (isChromium() && isSamsungInternet() && isChromium122OrNewer())
  );
}

/**
 * Starts rendering the audio context.
 * When the returned function is called, the render process starts finishing.
 */
function startRenderingAudio(context: OfflineAudioContext) {
  const renderTryMaxCount = 3;
  const renderRetryDelay = 500;
  const runningMaxAwaitTime = 500;
  const runningSufficientTime = 5000;
  let finalize = () => undefined as void;

  const resultPromise = new Promise<AudioBuffer>((resolve, reject) => {
    let isFinalized = false;
    let renderTryCount = 0;
    let startedRunningAt = 0;

    context.oncomplete = (event) => resolve(event.renderedBuffer);

    const startRunningTimeout = () => {
      setTimeout(
        () => reject(makeInnerError(InnerErrorName.Timeout)),
        Math.min(runningMaxAwaitTime, startedRunningAt + runningSufficientTime - Date.now())
      );
    };

    const tryRender = () => {
      try {
        const renderingPromise = context.startRendering();

        // `context.startRendering` has two APIs: Promise and callback, we check that it's really a promise just in case
        if (isPromise(renderingPromise)) {
          // Suppresses all unhandled rejections in case of scheduled redundant retries after successful rendering
          suppressUnhandledRejection(renderingPromise);
        }

        switch (context.state) {
          case 'running':
            startedRunningAt = Date.now();
            if (isFinalized) {
              startRunningTimeout();
            }
            break;

          // Sometimes the audio context doesn't start after calling `startRendering` (in addition to the cases where
          // audio context doesn't start at all). A known case is starting an audio context when the browser tab is in
          // background on iPhone. Retries usually help in this case.
          case 'suspended':
            // The audio context can reject starting until the tab is in foreground. Long fingerprint duration
            // in background isn't a problem, therefore the retry attempts don't count in background. It can lead to
            // a situation when a fingerprint takes very long time and finishes successfully.
            if (!document.hidden) {
              renderTryCount++;
            }
            if (isFinalized && renderTryCount >= renderTryMaxCount) {
              reject(makeInnerError(InnerErrorName.Suspended));
            } else {
              setTimeout(tryRender, renderRetryDelay);
            }
            break;
        }
      } catch (error) {
        reject(error);
      }
    };

    tryRender();

    finalize = () => {
      if (!isFinalized) {
        isFinalized = true;
        if (startedRunningAt > 0) {
          startRunningTimeout();
        }
      }
    };
  });

  return [resultPromise, finalize] as const;
}

function getHash(signal: ArrayLike<number>): number {
  let hash = 0;
  for (let i = 0; i < signal.length; ++i) {
    hash += Math.abs(signal[i]);
  }
  return hash;
}

function makeInnerError(name: InnerErrorName) {
  const error = new Error(name);
  error.name = name;
  return error;
}
