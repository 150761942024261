import { hasCrossOriginParent } from './utils/html';

export type ApplePayStates =
  | 'enabled'
  | 'disabled'
  | 'not-available'
  | 'rejected-iframe'
  | 'rejected-insecure';

/**
 * The return type is a union instead of the enum, because it's too challenging to embed the const enum into another
 * project. Turning it into a union is a simple and an elegant solution.
 */
export function getApplePayState(): ApplePayStates {
  // @ts-ignore
  const { ApplePaySession } = window;

  if (hasCrossOriginParent()) {
    return 'rejected-iframe';
  }

  if (typeof ApplePaySession?.canMakePayments !== 'function') {
    return 'not-available';
  }

  try {
    return ApplePaySession.canMakePayments() ? 'enabled' : 'disabled';
  } catch (error) {
    if (
      error instanceof Error &&
      error.name === 'InvalidAccessError' &&
      /\bfrom\b.*\binsecure\b/i.test(error.message)
    ) {
      return 'rejected-insecure';
    }
  }
}
