import { McfxModule, MCFXTracker } from './declarations';

export class Module implements McfxModule {
  tracker: MCFXTracker;

  constructor(tracker: MCFXTracker) {
    this.tracker = tracker;
  }

  listen() {}

  stop() {}
}

export default Module;
