import { Module } from './module';
import { dispatchEvent } from './utils/events';
import type { MCFXTracker, PersonalizationKeys } from './declarations';
import { isPlainObject, isDefinedOrEmpty } from './utils/helpers';
import { request } from './utils/request';

type Audience = {
  audienceId: string;
};

type Blocks = {
  active: boolean;
  audienceId: string;
  blockId: string;
  content: string;
  priority: number;
  targetId: string;
};

type Target = {
  audienceIds: string[];
  blocks: Blocks[];
  selector: string;
  targetId: string;
  urls: string[];
  replaceElement: boolean;
};

type PersonalizationObject = {
  audiences: Audience[];
  googleLocation: object;
  ip: string;
  siteId: number | string;
  targets: Target[];
  visitorId: string;
};

export class Personalization extends Module {
  ready: boolean;
  ssePath: string;
  sseConnection: any;
  pfx: Array<PersonalizationKeys>;
  matched: boolean;
  retryCount: number = 0;
  pfxObject: PersonalizationObject | null;
  previewId: string | null;
  contentSwapEndedAt: number | null;
  contentSwapStartedAt: number | null;
  sseConnectionOpenedAt: number | null;
  sseConnectionClosedAt: number | null;

  constructor(tracker: MCFXTracker) {
    super(tracker);
    this.tracker = tracker;
    this.pfx = [];
    this.tracker.session.set('pfx', this.pfx); // init in session
    this.matched = false;

    const urlParams = new URLSearchParams(window.location.search);
    const visitorId = this.tracker.session.get('uid');

    let docRef = `VIS_${visitorId}`;
    if (isDefinedOrEmpty(urlParams.get('previewBlockId'))) {
      this.previewId = urlParams.get('previewBlockId');
      docRef = `PRE_${this.previewId}`;
    }

    this.ssePath = `${this.tracker.configuration.agentUrl}/pfx/sse?sid=${this.tracker.configuration.siteId}_${docRef}`;

    this.onMessageHandler = this.onMessageHandler.bind(this);
    this.onErrorHandler = this.onErrorHandler.bind(this);
    this.onConnectionOpen = this.onConnectionOpen.bind(this);
    this.stop = this.stop.bind(this);

    this.match();
    this.listen();
    this.start();
  }

  start() {
    this.createEventSource();
  }

  listen() {
    window.addEventListener('pagehide', this.stop, { capture: true });
  }

  stop() {
    this.ready = false;
    this.retryCount = 0;
    this.previewId = null;
    this.pfxObject = null;

    if (this.sseConnection) {
      this.sseConnection.close();
    }
    this.sseConnection = null;
  }

  async match() {
    const visitorId = this.tracker.session.get('uid');

    if (!this.previewId && !visitorId) {
      return null;
    }

    await request(`${this.tracker.configuration.agentUrl}/pfx/match-visitor`).post({
      siteId: this.tracker.configuration.siteId,
      ...(visitorId ? { visitorId } : null),
      ...(this.previewId ? { previewBlockId: this.previewId } : null),
      ip: this.tracker.session.get('location')?.ip,
    });

    // if we havent swapped content in 20 seconds, stop listening unless on preview
    if (!this.previewId) {
      setTimeout(() => {
        this.stop();
      }, 1000 * 20);
    }
  }

  /**
   * Sometimes when the Cloud-Run maxes nodes is reached
   * GCP does a request capture and tries to free up some space
   * which throws a bunch of sse errors in the pixel, try catch
   * here is to allow us wait as the service re-assigns a handler
   *
   * https://webpagefx.mangoapps.com/msc/MTY4MjMxNl8xMjkwMzY3Ng
   */
  createEventSource(): void {
    try {
      if (this.sseConnection) {
        this.sseConnection.close?.();
      }

      this.sseConnection = new EventSource(this.ssePath);
      this.sseConnection.onerror = this.onErrorHandler;
      this.sseConnection.onopen = this.onConnectionOpen;
      this.sseConnection.onmessage = this.onMessageHandler;
    } catch (error) {
      dispatchEvent('pfx:sse-server-error', {
        error,
        visitorId: this.tracker.session.get('uid'),
        siteId: this.tracker.configuration.siteId,
        ssePath: this.ssePath,
      });
      this.onRetryConnection();
    }
  }

  onConnectionOpen(): void {
    this.ready = true;
    this.sseConnectionOpenedAt = new Date().getTime();
  }

  onMessageHandler(event: { data: string }): void {
    const data = JSON.parse(event.data);

    if (data.event === 'snapshot') {
      if (isPlainObject(data.data) && !Array.isArray(data.data)) {
        this.pfxObject = data.data;
      } else {
        /**
         * Do nothing as data pattern is unknown.
         * required to refrain PFX from hoarding the
         * client site with infinite error logs
         **/
        dispatchEvent('pfx:bad-content-block', {
          data,
        });
        return;
      }

      this.processBlocks();
    }

    if (data.event === 'missed') {
      return;
    }

    if (['error', 'warning', 'disconnect'].includes(data.event)) {
      this.onDisconnect();
    }

    if (data.event === 'error') {
      dispatchEvent('pfx:sse-server-error', {
        data,
        visitorId: this.tracker.session.get('uid'),
        siteId: this.tracker.configuration.siteId,
      });
    }
  }

  onErrorHandler(event: { type: string; target: object }): void {
    if (this.retryCount > 3) {
      dispatchEvent('pfx:sse-failing', {
        event,
        visitorId: this.tracker.session.get('uid'),
        siteId: this.tracker.configuration.siteId,
      });
      return;
    }
    this.onRetryConnection();
  }

  onDisconnect(): void {
    this.sseConnectionClosedAt = new Date().getTime();

    dispatchEvent('pfx:sse-connection-duration', {
      startedAt: this.sseConnectionOpenedAt,
      closedAt: this.sseConnectionClosedAt,
      diff: this.sseConnectionClosedAt - this.sseConnectionOpenedAt,
    });

    this.stop();
  }

  onRetryConnection(): void {
    if (this.retryCount > 3) {
      return;
    }
    this.retryCount++;

    setTimeout(() => {
      this.start();
    }, 10000);
  }

  processBlocks(): void {
    if (!isDefinedOrEmpty(this.pfxObject)) {
      return;
    }

    this.contentSwapStartedAt = new Date().getTime();

    this.matched = false;
    for (let i = 0; i < this.pfxObject.targets.length; i++) {
      const target: Target = this.pfxObject.targets[i];
      if (target) {
        if (isDefinedOrEmpty(target.blocks)) {
          return;
        }

        this.updateDOMcontent(target);
      }
    }

    this.contentSwapEndedAt = new Date().getTime();

    if (this.matched) {
      this.tracker.session.set('pfx', this.pfx);
    }

    dispatchEvent('pfx:content-swap-time', {
      startedAt: this.contentSwapStartedAt,
      endedAt: this.contentSwapEndedAt,
      diff: this.contentSwapEndedAt - this.contentSwapStartedAt,
    });

    // stop listening after performing content swap, unless on preview
    if (!this.previewId) {
      this.stop();
    }
  }

  updateDOMcontent(target: Target): void {
    const topBlock = target.blocks[0];
    const replaceElement = target.replaceElement ?? false;
    const els = document.querySelectorAll(`${target.selector}`);

    if (this.previewId) {
      els.forEach((el) => {
        if (replaceElement) {
          el.outerHTML = topBlock.content;
        } else {
          el.innerHTML = topBlock.content;
        }
      });
      // since this is a preview, we don't want to track the pfx
      dispatchEvent('pfx:replaced-content-block-preview', {
        visitorId: this.tracker.session.get('uid'),
        siteId: this.tracker.configuration.siteId,
        target,
        url: window.location.toString(),
      });
      return;
    }

    /**
     * We begin with a default of 'true' so all respective content-blocks
     * are replace for all pages instead of scoping changes to specific URLs
     */
    let urlMatched: boolean = !!els.length;
    const urlPath = `${window.location.origin}${window.location.pathname}`;

    if (!isDefinedOrEmpty(target.urls)) {
      urlMatched = target.urls?.some((url) => urlPath === url) ?? true;
    }

    if (urlMatched && els.length && topBlock.content) {
      els.forEach((el) => {
        if (replaceElement) {
          el.outerHTML = topBlock.content;
        } else {
          el.innerHTML = topBlock.content;
        }
      });
      this.matched = true;
      this.pfx.push({
        a: topBlock.audienceId,
        b: topBlock.blockId,
        t: target.targetId,
      });
      dispatchEvent('pfx:replaced-content-block', {
        visitorId: this.tracker.session.get('uid'),
        siteId: this.tracker.configuration.siteId,
        target,
        url: window.location.toString(),
      });
    }
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['pfx']: Personalization;
  }
}
