// import lifecycle from 'page-lifecycle';
import { Module } from './module';
import { PageView } from './page-view';
import { UserActivity } from './user-activity';
import { isDefined } from './utils/helpers';
import { beacon } from './utils/request';
import { parseUrl } from './utils/url';

import type { MCFXTracker } from './declarations';
import { dispatchEvent } from './utils/events';

type WindowState = 'active' | 'hidden' | 'passive' | 'frozen' | 'terminated';

/**
 * View/Visit Tracking
 * @param {*} tracker Tracker Instance
 */
export class Visits extends Module {
  lastPingAt: number | null;
  activity: UserActivity;
  page: PageView;

  windowState: WindowState;

  constructor(tracker: MCFXTracker) {
    super(tracker);

    this.lastPingAt = null; // last time event was sent to server
    this.activity = new UserActivity(1000 * 60 * 5); // 5 min

    this.page = new PageView(tracker.initializedAt);

    this.onWindowStateChange = this.onWindowStateChange.bind(this);
    this.onUriChange = this.onUriChange.bind(this);
    this.handleFreeze = this.handleFreeze.bind(this);
    this.handlePageHide = this.handlePageHide.bind(this);

    this.listen();
  }

  /**
   * Handles changes to the URI.
   *
   * @function
   * @name Tracker#onUriChange
   * @param {PopStateEvent} _event - The event object.
   * @returns {void}
   */
  onUriChange(_event: PopStateEvent) {
    const newurlState = parseUrl();
    const isNewUrl =
      this.tracker.configuration.urlComparedBy === 'href'
        ? newurlState?.href !== this.page.url?.href
        : newurlState?.pathname !== this.page.url.pathname;

    if (isNewUrl) {
      if (this.page.validTTL) {
        // set the old page inactive and send
        this.page.setWindowState('inactive');
        this.send();
      }
      //create a new age and send
      this.tracker.session.createSession();
      this.page = new PageView();
      this.send();
    }
  }

  /**
   * Handles changes to the window state.
   *
   * @function
   * @name Tracker#onWindowStateChange
   * @param  event - The event object containing the new and old window states.
   * @returns {void}
   */
  onWindowStateChange(_event?, nextState?: WindowState) {
    const oldState = this.windowState;
    const newState = nextState ?? this.getWindowState();

    if (oldState === newState) {
      return;
    }
    if ('active' === oldState) {
      this.activity.stop();

      // only send new passive event if TTL was not rotated
      if (this.page.validTTL) {
        this.page.setWindowState('inactive');
        this.send();
      }
    } else if ('active' === newState) {
      this.activity.start();

      if (this.page.validTTL && this.tracker.session.validTTL) {
        this.page.setWindowState('active');
      } else {
        this.tracker.session.createSession();
        this.page = new PageView();
      }

      this.send();
    } else if (typeof oldState === 'undefined') {
      this.send();
    }
    this.windowState = newState;
  }

  handleFreeze = () => {
    this.onWindowStateChange(null, 'frozen');
  };

  handlePageHide = (event: PageTransitionEvent) => {
    this.onWindowStateChange(null, event.persisted ? 'frozen' : 'terminated');
  };

  listen() {
    this.activity.watch(() => this.onWindowStateChange(null, 'passive'));
    this.activity.start();
    window.addEventListener('popstate', this.onUriChange);
    window.addEventListener('pushState', this.onUriChange);
    window.addEventListener('replaceState', this.onUriChange);

    // These lifecycle events to observe window state state
    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach((type) => {
      window.addEventListener(type, this.onWindowStateChange, { capture: true });
    });
    window.addEventListener('freeze', this.handleFreeze, { capture: true });
    window.addEventListener('pagehide', this.handlePageHide, { capture: true });
  }

  stop() {
    this.activity.stop();
    window.removeEventListener('popstate', this.onUriChange);
    window.removeEventListener('pushState', this.onUriChange);
    window.removeEventListener('replaceState', this.onUriChange);

    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach((type) => {
      window.removeEventListener(type, this.onWindowStateChange);
    });

    window.removeEventListener('freeze', this.handleFreeze);
    window.removeEventListener('pagehide', this.handlePageHide);
  }

  send() {
    const { onViewCallback, siteId } = this.tracker.configuration;
    this.lastPingAt = new Date().getTime();

    const params = {
      ...this.tracker.session.getPostValues(),
      vid: this.page.id,
      o: this.page.url.origin,
      u: this.page.url.href,
      te: this.page.createdAt,
      tl: this.page.timeOnPage,
      t: document.title,
      ttl: this.page.ttl,
      a: siteId,
    };

    // if the browser doesn't support sendBeacon
    // then we force sending using image pixel method
    if (!navigator.sendBeacon) {
      const image = new Image(1, 1);
      const paramsString = Object.entries(params)
        .map(
          ([key, val]) =>
            `${key}=${isDefined(val) ? encodeURIComponent(val as string | number | boolean) : ''}`
        )
        .join('&');

      const url = `${this.tracker.configuration.agentUrl}/visit?${paramsString}`;

      image.onload = () => {};
      image.src = url;
    } else {
      beacon(`${this.tracker.configuration.agentUrl}/visit`, JSON.stringify(params));
    }
    dispatchEvent('visit:collect', params);
    dispatchEvent('collect', params);
    onViewCallback?.(params);
  }

  getWindowState() {
    if (document.visibilityState === 'hidden') {
      return 'hidden';
    }
    if (document.hasFocus()) {
      return 'active';
    }

    return 'passive';
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['view']: Visits;
  }
}
