import { v4 as uuid } from 'uuid';
import { getAttributes } from './utils/dom';
import hash from './utils/hash';
import { dispatchEvent } from './utils/events';
import { getFormValues } from './utils/get-form-values';
import { getFieldTypes } from './utils/get-field-types';
import { Module } from './module';
import type { MCFXTracker } from './declarations';

/**
 * Form Submission Key
 */
export type SubmissionId = {
  id: string;
  ttl: number;
};

export type FormAttributes = {
  [key: string]: any;
  id: string | null;
  class: string | null;
  name: string | null;
  xpath: string;
  fxId: string;
};

/**
 * Form Tracking
 * @param {*} tracker Tracker Instance
 */
export class Forms extends Module {
  submissions: Map<string, SubmissionId>;

  constructor(tracker: MCFXTracker) {
    super(tracker);
    this.onFormCapture = this.onFormCapture.bind(this);
    this.submissions = new Map(
      Object.entries(JSON.parse(window.sessionStorage.getItem('fxFormSessions') || '{}'))
    );
    this.listen();
  }

  /**
   * Bind event handlers for form tracking
   */
  listen() {
    window.addEventListener('submit', this.onFormCapture, true);
  }

  /**
   * Removes event listeners for form tracking
   */
  stop() {
    window.removeEventListener('submit', this.onFormCapture);
  }

  onFormCapture(event: Event) {
    this.capture(event.target as HTMLFormElement);
  }

  /**
   * Manual method to capture the current state of a form element
   * and send to MCFX for parsing
   * @param {HTMLFormElement} form
   */
  capture(form: HTMLFormElement) {
    dispatchEvent('form:precapture', { form });
    const { trackInputs, onFormCapture, filterFormInputs } = this.tracker.configuration;

    // allow hook to modify data and prevent sending data
    const captureCallback = onFormCapture?.(form);
    if (captureCallback === false) {
      return;
    }

    const submissionId: string = this.getSubmissionId(form);
    const values = filterFormInputs(trackInputs ? getFormValues(form) : {}, form);
    const fieldTypes = trackInputs ? getFieldTypes(form) : {};
    const formAttributes = {
      ...getAttributes(form),
      fxId: this.getOrCreateId(form),
    };

    const isMeeting = form.getAttribute('data-form-type') === 'scheduler';

    dispatchEvent('form:postcapture', { form, submissionId, values, fieldTypes, formAttributes });

    this.tracker.send({
      type: isMeeting ? 'meeting' : 'form',
      value: {
        id: submissionId,
        form: formAttributes,
        values,
        types: fieldTypes,
      },
    });

    return submissionId;
  }

  /**
   * Deprecated method to send form data to MCFX
   * passes directly
   * @deprecated
   * @param {Element<Form>} form
   * @param {FormData} values
   * @param {Object} fieldTypes
   * @returns {string} submissionId
   */
  send(form: HTMLFormElement, _values, _fieldTypes) {
    this.capture(form);
  }

  /**
   * Retrieve and or creates the MCFX based Id for a form element
   * @param {HTMLFormElement} form
   * @returns
   */
  getOrCreateId(form: HTMLFormElement): string {
    const k = form ? form.getAttribute('data-fx-id') : '';
    if (k && k !== '') {
      return k;
    }

    const { xpath } = getAttributes(form);
    const id = `${hash(xpath)}`;

    if (form) {
      form.setAttribute('data-fx-id', id);
    }
    return id;
  }

  /**
   * Retrieve the session based submissionId for a form element
   * @param {Element<Form>} form
   * @returns
   */
  getSubmissionId(form: HTMLFormElement) {
    const formId = this.getOrCreateId(form);
    const now = 1 * new Date().getTime();

    const currentSubmissionId = this.submissions.get(formId);

    const submissionId =
      currentSubmissionId && now < currentSubmissionId.ttl
        ? currentSubmissionId.id
        : this.generateNewSessionId(formId);

    return submissionId;
  }

  /**
   * generate a new session submissionId for a form element
   * @param {Element<Form>} form
   * @returns {string} uuid
   */
  generateNewSessionId(formId: string) {
    const { formSessionLength } = this.tracker.configuration;
    const newSessionId: SubmissionId = {
      id: uuid(),
      ttl: 1 * new Date().getTime() + 1000 * 60 * formSessionLength,
    };

    this.submissions.set(formId, newSessionId);
    window.sessionStorage.setItem(
      'fxFormSessions',
      JSON.stringify(Object.fromEntries(this.submissions))
    );

    return newSessionId.id;
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    forms: Forms;
  }
}
