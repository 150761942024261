/* eslint-disable prefer-rest-params */

/**
 * Overwrite pushstate and replaceState to attach page load events
 * for history usage
 * @param {*} type
 */
const _wr = function (type) {
  const orig = history[type];
  return function () {
    const rv = orig.apply(this, arguments);
    const e = new Event(type);
    // @ts-ignore
    e.arguments = arguments;
    window.dispatchEvent(e);
    return rv;
  };
};

window.history.pushState = _wr('pushState');
window.history.replaceState = _wr('replaceState');
