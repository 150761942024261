/**
 * Returns either a named selector or resulting nth-of-type relative to parent element
 * @param {HTMLElement} node
 * @returns {string} id, class, name, or nth-of-type selector
 */
export function selector(node: HTMLElement): string {
  if (node.getAttribute('id')) {
    return `#${node.getAttribute('id')}`;
  }

  if (node.className && node.className !== '') {
    return classNameToCss(node.className);
  }

  if (node.getAttribute('name')) {
    return `[name="${node.getAttribute('name')}"]`;
  }

  const siblings = Array.from(node.parentElement?.children || []).filter(
    (el) => el.tagName === node.tagName
  );
  return siblings.indexOf(node)
    ? `${node.tagName}:nth-of-type(${siblings.indexOf(node) + 1})`
    : `${node.tagName}`;
}

/**
 * Converts a className string to a CSS selector.
 *
 * @param {string} classname - The class name to convert.
 * @returns {string} The CSS selector for the class name.
 * @example
 * classNameToCss('foo'); // => '.foo.bar'
 * classNameToCss('foo bar baz'); // => '.foo.bar.baz'
 */
export function classNameToCss(classname: string): string {
  if (!classname) {
    return '';
  }

  let normalized = `.${classname}`;
  while (normalized.indexOf(' ') !== -1) {
    normalized = normalized.replace(' ', '.');
  }
  return normalized;
}

/**
 * Generates a unqiue xpath to a HTML node element
 * @param {HTMLElement} node
 * @returns {string} the selector path
 */
export function xPath(node: HTMLElement): string {
  let path = '';

  while (node.parentElement) {
    path = selector(node) + `${path ? ' > ' : ''}${path}`;
    node = node.parentElement;
  }

  return `html > ${path.toLowerCase()}`;
}

/**
 * return attributes from an element
 * @param  {HTMLElement} node node element to capture selectors of
 * @return {object}  object with different selection types
 */
export function getAttributes(node: HTMLElement) {
  return {
    ...node.getAttributeNames().reduce((acc, name) => {
      return { ...acc, [name]: node.getAttribute(name) };
    }, {}),
    id: node.getAttribute('id') ? `${node.getAttribute('id')}` : null,
    class: node.className && node.className !== '' ? classNameToCss(node.className) : null,
    name: node.getAttribute('name') ? `${node.getAttribute('name')}` : null,
    xpath: xPath(node),
  };
}
