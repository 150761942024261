import { protectSensitiveData } from './helpers';

/**
 * Returns the values of any inputs within a form.
 * @param {Element<Form>} form the form element to get values from
 * @param {Object} options {skipProtection, dontTrackInputs}
 * @returns
 */
export function getFormValues(form: HTMLFormElement, skipProtection: boolean = false) {
  if (!form) {
    throw new Error('You must provide a form.');
  }
  const formData = new FormData(form);

  const d = Object.keys(Object.fromEntries(formData as any)).reduce((d, k) => {
    const value = formData.getAll(k);
    d[k.toLowerCase()] = value.length > 1 ? value : value[0];
    return d;
  }, {});

  Array.from(form.elements).forEach((el: Element) => {
    const type = `${(el as HTMLInputElement).type}`.toLowerCase();
    if (['submit', 'reset', 'button'].includes(type)) {
      return;
    }
    const name = (el as HTMLInputElement).name;
    const key = name ? name.toLowerCase() : el.getAttribute('id')?.toLowerCase();

    // sets non entered values to null
    if (key && d[key] === undefined) {
      d[key] = null;
    }
  });

  if (skipProtection) {
    return d;
  }

  return protectSensitiveData(d, form);
}
