import { ATTRIBUTION_PARAM_MAP } from '../constants';

import { isDefined } from './helpers';
import type { ParsedUrl } from '../declarations';
import type { Session } from '../session';
import { URLSearchParamsIterator } from 'url';

/**
 * Parses a URL and returns an object with its components.
 * @param {string} [url=window.location.href] - The URL to parse. If not provided, the current page URL is used.
 * @returns {ParsedUrl} An object with the parsed URL components, or null if the URL is invalid.
 */
export function parseUrl(url?: string): ParsedUrl | null {
  try {
    const { hostname, href, origin, pathname, search, protocol, hash, searchParams } = new URL(
      url || window.location.href
    );

    const out = {
      hostname,
      href,
      origin,
      pathname,
      search,
      searchParams: Object.fromEntries(searchParams),
      protocol,
      hash,
      domain: '',
      subdomain: '',
      tld: '',
      cookieDomain: '',
    };

    if (hostname) {
      out.domain = getDomain(hostname);
      out.subdomain = hostname
        .replace(out.domain, '')
        .split('.')
        .filter((i) => i)
        .join('.');
      out.tld = out.domain.split('.').slice(1).join('.');
      out.cookieDomain = `.${out.domain}`;
    }

    return out;
  } catch (e) {
    return null;
  }
}

/**
 * Parses URL parameters from a string and returns an object with their values.
 * @param {string} [params] - The URL parameters to parse. If not provided, the current page URL parameters are used.
 * @returns {Record<string, string>} An object with the parsed URL parameters and their values.
 */
export function parseUrlParams(params?: string): Record<string, string> {
  const _params = new URLSearchParams(params || window.location.search);
  const parsed = {};

  for (const key of _params.keys()) {
    const val = _params.getAll(key);
    if (val.length === 1) {
      parsed[key] = val[0];
    } else {
      parsed[key] = val;
    }
  }

  return parsed;
}

/**
 * Gets the value of a URL parameter by name.
 * @param {string} name - The name of the URL parameter to get.
 * @param {object} [_urlParams] - An optional tracker object containing the URL parameters. If not provided, the current page URL parameters are used.
 * @returns {string|null} The value of the URL parameter, or null if it does not exist.
 */
export function getUrlParam(name: string, _urlParams?: Record<string, string>): string | null {
  const urlParams = _urlParams || parseUrlParams();
  return urlParams[name] || null;
}

/**
 * Returns the domain name of a hostname, or the domain name of the current page if no hostname is provided.
 * @param {string} [_hostname] - The hostname to get the domain name from. If not provided, the domain name of the current page is used.
 * @returns {string} The domain name of the provided hostname, or the domain name of the current page if no hostname is provided.
 */
export function getDomain(_hostname?: string): string {
  const domain = _hostname ?? location.hostname;
  const MAX_TLD_LENGTH = 3;

  // Split the domain into parts
  const parts = domain.split('.');

  // Handle cases where the TLD is part of a longer domain structure (e.g., co.uk)
  // Default to the last two parts if the TLD is longer than 3 characters
  if (
    parts.length > 2 &&
    parts[parts.length - 2].length <= MAX_TLD_LENGTH &&
    parts[parts.length - 1].length <= MAX_TLD_LENGTH
  ) {
    return parts.slice(parts.length - 3).join('.');
  } else {
    return parts.slice(parts.length - 2).join('.');
  }
}

/**
 * Extracts decoration (fx_) parameters from the given object or from the URL query parameters.
 * @param _params - Optional object containing the parameters.
 * @returns An object containing the extracted decoration parameters.
 */
export function extractDecorationParams(_params?: Record<string, any>): Record<string, any> {
  return Object.entries(_params ?? parseUrlParams()).reduce((accum, [paramKey, paramValue]) => {
    // Don't risk overwriting any existing params unless a value is actually set
    if (paramKey.indexOf('fx_') === 0 && paramValue) {
      // Skip known bad values
      if (['[object Object]'].includes(paramValue)) {
        return accum;
      }

      // link decoration should only contain single values
      // - if given an array, take the last item in the array
      accum[paramKey.replace('fx_', '')] = Array.isArray(paramValue)
        ? paramValue.pop()
        : paramValue;
    }

    return accum;
  }, {});
}

/**
 * Extracts attribution parameters from the given object or from the URL query parameters.
 * @param _params - Optional object containing the parameters.
 * @returns {Record<string, any>} An object containing the extracted attribution parameters.
 */
export function extractAttributionParams(_params?: Record<string, any>): Record<string, any> {
  const flatAttributionParams = Object.entries(ATTRIBUTION_PARAM_MAP).reduce(
    (accum, [paramKey, paramValue]) => {
      return [...accum, paramKey, ...paramValue];
    },
    []
  );

  return Object.entries(_params ?? parseUrlParams()).reduce((accum, [paramKey, paramValue]) => {
    if (ATTRIBUTION_PARAM_MAP[paramKey]) {
      ATTRIBUTION_PARAM_MAP[paramKey].forEach((key) => {
        accum[key] = paramValue;
      });
    }
    // include any other utm params
    else if (paramKey.indexOf('utm_') === 0) {
      accum[paramKey] = paramValue;
    }
    // uses decorated params
    else if (
      paramKey.indexOf('fx_') === 0 &&
      flatAttributionParams.includes(paramKey.replace('fx_', ''))
    ) {
      accum[paramKey.replace('fx_', '')] = paramValue;
    }

    return accum;
  }, {});
}

/**
 * Decorates links on the page with session data.
 * @param {string | string[]} urls - The URLs to decorate.
 * @param {Session | Record<string, any>} session - The session object to use for decoration.
 * @param {boolean} skipSessionValidation - Whether to skip session validation.
 */
export function decorate(
  urls: string | string[],
  session?: Session | Record<string, any>,
  skipSessionValidation?: boolean
) {
  if (!urls) {
    return;
  }
  if (!Array.isArray(urls)) {
    urls = [urls];
  }

  const links = document.querySelectorAll('a');
  const { r, ttl, l, ip, fp, pfx, ...sess } = session?.getPostValues?.() ?? session ?? {};

  if (!sess.s && !skipSessionValidation) {
    setTimeout(() => {
      decorate(urls, session, true);
    }, 5000);
    return;
  }

  links.forEach((link) => {
    const parsed = parseUrl(link.href);

    if (!parsed) {
      return;
    }

    const params = parseUrlParams(parsed.search);

    urls.forEach((url) => {
      if (link.href.indexOf(url) !== -1) {
        const newParams = new URLSearchParams();
        const data = {
          ...params,
          ...Object.entries(sess).reduce((accum, [k, v]) => {
            if (isDefined(v)) {
              accum[`fx_${k}`] = v;
            }
            return accum;
          }, {}),
        };

        Object.entries(data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((value) => newParams.append(key, value.toString()));
          } else {
            newParams.append(key, value.toString());
          }
        });

        link.href = `${parsed.origin}${parsed.pathname}?${newParams.toString()}${parsed.hash}`;
      }
    });
  });
}
