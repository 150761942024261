function countStatements(statements: boolean[]) {
  return statements.reduce((acc, statement) => {
    try {
      return acc + Number(statement);
    } catch (e) {
      return acc;
    }
  }, 0);
}

export function getEngine() {
  const x = [].constructor;
  try {
    (-1).toFixed(-1);
  } catch (err) {
    return err.message.length + (x + '').split(x.name).join('').length;
  }
}

/**
 * Determines the operating system from a user agent string.
 *
 * @param {string} userAgent - The browser's user agent string to analyze
 * @returns {string} The detected operating system name ('Windows Phone', 'Windows', 'Android',
 *                   'Chrome OS', 'Linux', 'iPad', 'iPhone', 'iPod', 'iOS', 'Mac', or 'Other')
 *
 * @example
 * const os = getOS(navigator.userAgent);
 * // Returns 'Windows' for "Mozilla/5.0 (Windows NT 10.0; Win64; x64) ..."
 * // Returns 'Mac' for "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) ..."
 */
export function getOS(userAgent: string) {
  const patterns = [
    { pattern: /windows phone/gi, os: 'Windows Phone' },
    { pattern: /win(dows|16|32|64|95|98|nt)|wow64/gi, os: 'Windows' },
    { pattern: /android/gi, os: 'Android' },
    { pattern: /cros/gi, os: 'Chrome OS' },
    { pattern: /linux/gi, os: 'Linux' },
    { pattern: /ipad/gi, os: 'iPad' },
    { pattern: /iphone/gi, os: 'iPhone' },
    { pattern: /ipod/gi, os: 'iPod' },
    { pattern: /ios/gi, os: 'iOS' },
    { pattern: /mac/gi, os: 'Mac' },
  ];

  return patterns.find(({ pattern }) => pattern.test(userAgent))?.os || 'Other';
}

/**
 * Checks if the browser is Brave.
 *
 * @returns {boolean} True if the browser is Brave, false otherwise
 */
export function isBrave(): boolean {
  return (
    'brave' in navigator &&
    // @ts-ignore
    Object.getPrototypeOf(navigator.brave).constructor.name == 'Brave' &&
    // @ts-ignore
    navigator.brave.isBrave.toString() == 'function isBrave() { [native code] }'
  );
}

/**
 * Checks if the browser is based on the Blink rendering engine.
 *
 * @returns {boolean} True if the browser is based on Blink, false otherwise
 */
export function isBlink(): boolean {
  return getEngine() == 80;
}

/**
 * Checks if the browser is based on the Gecko rendering engine.
 *
 * @returns {boolean} True if the browser is based on Gecko, false otherwise
 */
export function isGecko(): boolean {
  if (getEngine() == 58) {
    return true;
  }
  return (
    countStatements([
      'MozAppearance' in (document.documentElement?.style ?? {}),
      'buildID' in navigator,
      'CanvasCaptureMediaStream' in window,
      'CSSMozDocumentRule' in window,
      'onmozfullscreenchange' in window,
      'mozInnerScreenX' in window,
    ]) >= 4
  );
}

/**
 * Checks if the browser is based on the WebKit rendering engine.
 *
 * @returns {boolean} True if the browser is based on WebKit, false otherwise
 */
export const isWebKit = () => {
  if (getEngine() == 77) {
    return true;
  }

  return (
    countStatements([
      navigator.vendor.indexOf('Apple') === 0,
      'ApplePayError' in window,
      'CSSPrimitiveValue' in window,
      'Counter' in window,
      'RGBColor' in window,
      'WebKitMediaKeys' in window,
    ]) >= 4
  );
};

/**
 * Gets the name of the JavaScript engine used by the browser.
 *
 * @returns {string|null} The name of the engine ('V8', 'SpiderMonkey', 'JavaScriptCore', or null if unknown)
 */
export function getEngineName() {
  return (
    {
      80: 'V8',
      58: 'SpiderMonkey',
      77: 'JavaScriptCore',
    }[getEngine()] || null
  );
}

/**
 * Checks if the browser is based on the Trident rendering engine.
 *
 * @returns {boolean} True if the browser is based on Trident, false otherwise
 */
export function isTrident(): boolean {
  return (
    countStatements([
      'msMaxTouchPoints' in navigator,
      'msPointerEnabled' in navigator,
      'MSCSSMatrix' in window,
      'msIndexedDB' in window,
      'msSetImmediate' in window,
    ]) >= 4
  );
}

export function isEdge(): boolean {
  return (
    countStatements([
      'msLaunchUri' in navigator,
      'msSaveBlob' in navigator,
      'MSStream' in window,
      'msWriteProfilerMark' in window,
    ]) >= 3 && !isTrident()
  );
}

/**
 * Checks if the browser is based on Chromium.
 *
 * @returns {boolean} True if the browser is based on Chromium, false otherwise
 */
export function isChromium(): boolean {
  return (
    countStatements([
      navigator.vendor.indexOf('Google') === 0,
      'webkitPersistentStorage' in navigator,
      'webkitTemporaryStorage' in navigator,
      'BatteryManager' in window,
      'webkitResolveLocalFileSystemURL' in window,
      'webkitMediaStream' in window,
      'webkitSpeechGrammar' in window,
    ]) >= 5
  );
}

/**
 * Checks whether this WebKit browser is a desktop browser.
 * It doesn't check that the browser is based on WebKit, there is a separate function for this.
 *
 * Warning for package users:
 * This function is out of Semantic Versioning, i.e. can change unexpectedly. Usage is at your own risk.
 */
export function isDesktopWebKit(): boolean {
  const { HTMLElement, Document } = window;

  return (
    countStatements([
      Document && 'pointerLockElement' in Document.prototype,
      HTMLElement && !('autocapitalize' in HTMLElement.prototype),
      !('ongestureend' in window),
      !('TouchEvent' in window),
      !('orientation' in window),
      'safari' in window, // Always false in Karma and BrowserStack Automate
    ]) >= 4
  );
}

/**
 * Checks whether this WebKit browser is Safari.
 */
export function isSafariWebKit(): boolean {
  return (
    String((window as unknown as Record<string, unknown>).browser) === '[object WebPageNamespace]'
  );
}

/**
 * Checks if the browser is Firefox.
 *
 * @returns {boolean} True if the browser is Firefox, false otherwise
 */
export function isFirefox(): boolean {
  return (
    countStatements([
      'buildID' in navigator,
      'MozAppearance' in (document.documentElement?.style ?? {}),
      'onmozfullscreenchange' in window,
      'mozInnerScreenX' in window,
      'CSSMozDocumentRule' in window,
      'mozCancelFullScreen' in document,
    ]) >= 4 && isGecko()
  );
}

/**
 * Checks whether the browser is based on Chromium version ≥86 without using user-agent.
 * It doesn't check that the browser is based on Chromium, there is a separate function for this.
 */
export function isChromium86OrNewer(): boolean {
  return (
    countStatements([
      !('MediaSettingsRange' in window),
      'RTCEncodedAudioFrame' in window,
      '' + window.Intl === '[object Intl]',
      '' + window.Reflect === '[object Reflect]',
    ]) >= 3
  );
}

/**
 * Checks if the browser is based on Chromium version ≥122 without using user-agent.
 */
export function isChromium122OrNewer(): boolean {
  // @ts-ignore
  const { URLPattern } = window;

  return (
    countStatements([
      'union' in Set.prototype,
      'Iterator' in window,
      URLPattern && 'hasRegExpGroups' in URLPattern.prototype,
      'RGB8' in WebGLRenderingContext.prototype,
    ]) >= 3
  );
}

/**
 * Checks whether the browser is based on WebKit version ≥606 (Safari ≥12) without using user-agent.
 *
 * @see https://en.wikipedia.org/wiki/Safari_version_history#Release_history Safari-WebKit versions map
 */
export function isWebKit606OrNewer(): boolean {
  return (
    countStatements([
      'DOMRectList' in window,
      'RTCPeerConnectionIceEvent' in window,
      'SVGGeometryElement' in window,
      'ontransitioncancel' in window,
    ]) >= 3
  );
}

/**
 * Checks whether the browser is based on WebKit version ≥616 (Safari ≥17) without using user-agent.
 *
 * @see https://developer.apple.com/documentation/safari-release-notes/safari-17-release-notes Safari 17 release notes
 * @see https://tauri.app/v1/references/webview-versions/#webkit-versions-in-safari Safari-WebKit versions map
 */
export function isWebKit616OrNewer(): boolean {
  const { CSS, HTMLButtonElement } = window;

  return (
    countStatements([
      !('getStorageUpdates' in navigator),
      HTMLButtonElement && 'popover' in HTMLButtonElement.prototype,
      'CSSCounterStyleRule' in window,
      CSS.supports('font-size-adjust: ex-height 0.5'),
      CSS.supports('text-transform: full-width'),
    ]) >= 4
  );
}

/**
 * Checks if the browser is Safari ≥15.
 */
export function isSafari15AndAbove() {
  return 'BigInt64Array' in window && isWebKit() && !/(Cr|Fx)iOS/.test(navigator.userAgent);
}

/**
 * Checks whether the device is an iPad.
 */
export function isIPad(): boolean {
  if (navigator.platform === 'iPad') {
    return true;
  }

  const s = screen;
  const screenRatio = s.width / s.height;

  return (
    countStatements([
      // Since iOS 13. Doesn't work in Chrome on iPadOS <15, but works in desktop mode.
      'MediaSource' in window,
      // Since iOS 12. Doesn't work in Chrome on iPadOS.
      // @ts-expect-error
      !!Element.prototype.webkitRequestFullscreen,
      // iPhone 4S that runs iOS 9 matches this, but it is not supported
      // Doesn't work in incognito mode of Safari ≥17 with split screen because of tracking prevention
      screenRatio > 0.65 && screenRatio < 1.53,
    ]) >= 2
  );
}

/**
 * Gets the fullscreen element.
 *
 * @returns {Element|null} The fullscreen element, or null if no element is currently fullscreen
 */
export function getFullscreenElement(): Element | null {
  return (
    document.fullscreenElement ||
    // @ts-ignore
    document.msFullscreenElement ||
    // @ts-ignore
    document.mozFullScreenElement ||
    // @ts-ignore
    document.webkitFullscreenElement ||
    null
  );
}

/**
 * Exits the fullscreen mode.
 *
 * @returns {Promise<void>} A promise that resolves when the fullscreen mode is exited
 */
export function exitFullscreen(): Promise<void> {
  return (
    document.exitFullscreen ||
    // @ts-ignore
    document.msExitFullscreen ||
    // @ts-ignore
    document.mozCancelFullScreen ||
    // @ts-ignore
    document.webkitExitFullscreen
  );
}

/**
 * Checks if the browser is running on Android.
 *
 * @returns {boolean} True if the browser is running on Android, false otherwise
 */
export function isAndroid(): boolean {
  const isItChromium = isChromium();
  const isItGecko = isGecko();
  const w = window;
  const n = navigator;
  const c = 'connection';

  // Chrome removes all words "Android" from `navigator` when desktop version is requested
  // Firefox keeps "Android" in `navigator.appVersion` when desktop version is requested
  if (isItChromium) {
    return (
      countStatements([
        !('SharedWorker' in w),
        // `typechange` is deprecated, but it's still present on Android (tested on Chrome Mobile 117)
        // Removal proposal https://bugs.chromium.org/p/chromium/issues/detail?id=699892
        // Note: this expression returns true on ChromeOS, so additional detectors are required to avoid false-positives
        n[c] && 'ontypechange' in n[c],
        !('sinkId' in new Audio()),
      ]) >= 2
    );
  } else if (isItGecko) {
    return (
      countStatements([
        'onorientationchange' in w,
        'orientation' in w,
        /android/i.test(n.appVersion),
      ]) >= 2
    );
  } else {
    // Only 2 browser engines are presented on Android.
    // Actually, there is also Android 4.1 browser, but it's not worth detecting it at the moment.
    return false;
  }
}

export function isSamsungInternet(): boolean {
  // Checked in Samsung Internet 21, 25 and 27
  const n = navigator;
  const w = window;
  const audioPrototype = Audio.prototype;
  const { visualViewport } = w;

  return (
    countStatements([
      'srLatency' in audioPrototype,
      'srChannelCount' in audioPrototype,
      'devicePosture' in n, // Not available in HTTP
      visualViewport && 'segments' in visualViewport,
      'getTextInformation' in Image.prototype, // Not available in Samsung Internet 21
    ]) >= 3
  );
}

/**
 * Checks if the browser is Brave-like.
 *
 * @returns {boolean} True if the browser is Brave-like, false otherwise
 */
export function isBraveLike() {
  return (
    isBlink() &&
    'flat' in Array.prototype /* Chrome 69 */ &&
    !(('ReportingObserver' in self) /* Brave */)
  );
}

export type BraveMode = {
  unknown: boolean;
  allow: boolean;
  standard: boolean;
  strict: boolean;
};

/**
 * Determines the Brave browser mode.
 *
 * @returns {BraveMode} The Brave browser mode
 */
export function getBraveMode(): BraveMode {
  const mode = {
    unknown: false,
    allow: false,
    standard: false,
    strict: false,
  };
  try {
    // strict mode adds float frequency data AnalyserNode
    const strictMode = () => {
      try {
        window.OfflineAudioContext =
          // @ts-ignore
          OfflineAudioContext || webkitOfflineAudioContext;
      } catch (err) {}

      if (!window.OfflineAudioContext) {
        return false;
      }
      const context = new OfflineAudioContext(1, 1, 44100);
      const analyser = context.createAnalyser();
      const data = new Float32Array(analyser.frequencyBinCount);
      analyser.getFloatFrequencyData(data);
      const strict = new Set(data).size > 1; // native only has -Infinity
      return strict;
    };

    if (strictMode()) {
      mode.strict = true;
      return mode;
    }
    // standard and strict mode do not have chrome plugins
    const chromePlugins = /(Chrom(e|ium)|Microsoft Edge) PDF (Plugin|Viewer)/;
    const pluginsList = [...Array.from(navigator.plugins)];
    const hasChromePlugins =
      pluginsList.filter((plugin) => chromePlugins.test(plugin.name)).length == 2;
    if (pluginsList.length && !hasChromePlugins) {
      mode.standard = true;
      return mode;
    }
    mode.allow = true;
    return mode;
  } catch (e) {
    mode.unknown = true;
    return mode;
  }
}

/**
 * Filter parameters to Brave unprotected parameters only
 *
 * @param {Object} parameters - The parameters object
 * @returns {Object} The protected parameters
 */
export function getBraveUnprotectedParameters(parameters) {
  const blocked = new Set([
    'FRAGMENT_SHADER.HIGH_FLOAT.precision',
    'FRAGMENT_SHADER.HIGH_FLOAT.rangeMax',
    'FRAGMENT_SHADER.HIGH_FLOAT.rangeMin',
    'FRAGMENT_SHADER.HIGH_INT.precision',
    'FRAGMENT_SHADER.HIGH_INT.rangeMax',
    'FRAGMENT_SHADER.HIGH_INT.rangeMin',
    'FRAGMENT_SHADER.LOW_FLOAT.precision',
    'FRAGMENT_SHADER.LOW_FLOAT.rangeMax',
    'FRAGMENT_SHADER.LOW_FLOAT.rangeMin',
    'FRAGMENT_SHADER.MEDIUM_FLOAT.precision',
    'FRAGMENT_SHADER.MEDIUM_FLOAT.rangeMax',
    'FRAGMENT_SHADER.MEDIUM_FLOAT.rangeMin',
    'MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS',
    'MAX_COMBINED_UNIFORM_BLOCKS',
    'MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS',
    'MAX_DRAW_BUFFERS_WEBGL',
    'MAX_FRAGMENT_INPUT_COMPONENTS',
    'MAX_FRAGMENT_UNIFORM_BLOCKS',
    'MAX_FRAGMENT_UNIFORM_COMPONENTS',
    'MAX_TEXTURE_MAX_ANISOTROPY_EXT',
    'MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS',
    'MAX_UNIFORM_BUFFER_BINDINGS',
    'MAX_VARYING_COMPONENTS',
    'MAX_VERTEX_OUTPUT_COMPONENTS',
    'MAX_VERTEX_UNIFORM_BLOCKS',
    'MAX_VERTEX_UNIFORM_COMPONENTS',
    'SHADING_LANGUAGE_VERSION',
    'UNMASKED_RENDERER_WEBGL',
    'UNMASKED_VENDOR_WEBGL',
    'VERSION',
    'VERTEX_SHADER.HIGH_FLOAT.precision',
    'VERTEX_SHADER.HIGH_FLOAT.rangeMax',
    'VERTEX_SHADER.HIGH_FLOAT.rangeMin',
    'VERTEX_SHADER.HIGH_INT.precision',
    'VERTEX_SHADER.HIGH_INT.rangeMax',
    'VERTEX_SHADER.HIGH_INT.rangeMin',
    'VERTEX_SHADER.LOW_FLOAT.precision',
    'VERTEX_SHADER.LOW_FLOAT.rangeMax',
    'VERTEX_SHADER.LOW_FLOAT.rangeMin',
    'VERTEX_SHADER.MEDIUM_FLOAT.precision',
    'VERTEX_SHADER.MEDIUM_FLOAT.rangeMax',
    'VERTEX_SHADER.MEDIUM_FLOAT.rangeMin',
  ]);
  const safeParameters = Object.keys(parameters).reduce((acc, curr) => {
    if (blocked.has(curr)) {
      return acc;
    }
    acc[curr] = parameters[curr];
    return acc;
  }, {});

  return safeParameters;
}

/**
 * Restores and normalizes a user agent string using high-entropy client hints data.
 * This function handles various platform-specific modifications including Chrome, Windows,
 * ChromeOS, Android, and macOS versions.
 *
 * @param {Object} params - The parameters object
 * @param {string} params.userAgent - The original user agent string to modify
 * @param {Object} params.userAgentData - High-entropy client hints data
 * @param {Array<Object>} params.userAgentData.brands - Browser brand information
 * @param {string} params.userAgentData.uaFullVersion - Full browser version
 * @param {string} params.userAgentData.platformVersion - OS platform version
 * @param {string} params.userAgentData.model - Device model (for mobile devices)
 * @param {string} params.userAgentData.bitness - Architecture bitness (32/64)
 * @param {string} params.fontPlatformVersion - Font-based platform version detection (for Windows)
 *
 * @returns {string|undefined} The restored user agent string, or undefined if userAgentData is not available
 *
 * @example
 * const restored = getRestoredUserAgent({
 *   userAgent: navigator.userAgent,
 *   userAgentData: navigator.userAgentData,
 *   fontPlatformVersion: "8.1"
 * });
 */
export function getRestoredUserAgent({ userAgent, userAgentData, fontPlatformVersion = '' }) {
  if (!userAgentData) {
    return userAgent;
  }
  const { brands, uaFullVersion, platformVersion, model: deviceModel, bitness } = userAgentData;

  // Use isChromium() to detect Chrome-based browsers
  const isGoogleChrome = isChromium() && !!(brands || []).find((x) => x == 'Google Chrome');
  const versionNumber = +(/(\d+)\./.exec(platformVersion) || [])[1];
  const windowsFontVersion = (/8\.1|8|7/.exec(fontPlatformVersion) || [])[0];
  const windowsVersion =
    versionNumber >= 13 ? '11' : versionNumber == 0 ? windowsFontVersion || '7/8/8.1' : '10';
  const windowsVersionMap = {
    '7': 'NT 6.1',
    '8': 'NT 6.2',
    '8.1': 'NT 6.3',
    '10': 'NT 10.0',
  };

  // Use isWebKit() to help determine macOS version handling
  const macVersion = platformVersion.replace(/\./g, '_');

  return userAgent
    .replace(/(Chrome\/)([^\s]+)/, (match, p1, p2) => `${p1}${isGoogleChrome ? uaFullVersion : p2}`)
    .replace(/Windows NT 10.0/, `Windows ${windowsVersionMap[windowsVersion] || windowsVersion}`)
    .replace(/(X11; CrOS x86_64)/, (match, p1) => `${p1} ${platformVersion}`)
    .replace(
      /(Linux; Android )(10)(; K|)/,
      (match, p1, p2, p3) =>
        `${p1}${versionNumber}${!p3 ? '' : deviceModel ? `; ${deviceModel}` : '; K'}`
    )
    .replace(
      /(Macintosh; Intel Mac OS X )(10_15_7)/,
      (match, p1) => `${isWebKit() ? p1 : p1.replace('X ', '')}${macVersion}`
    )
    .replace(/(; Win64; x64| x86_64)/, (match, p1) => (bitness === '64' ? p1 : ''));
}

function decryptUserAgent({ ua, os, isBrave }) {
  const apple = /ipad|iphone|ipod|ios|mac/gi.test(os);
  const isOpera = /OPR\//g.test(ua);
  const isVivaldi = /Vivaldi/g.test(ua);
  const isDuckDuckGo = /DuckDuckGo/g.test(ua);
  const isYandex = /YaBrowser/g.test(ua);
  const paleMoon = ua.match(/(palemoon)\/(\d+)./i);
  const edge = ua.match(/(edgios|edg|edge|edga)\/(\d+)./i);
  const edgios = edge && /edgios/i.test(edge[1]);
  const chromium = ua.match(/(crios|chrome)\/(\d+)./i);
  const firefox = ua.match(/(fxios|firefox)\/(\d+)./i);
  const likeSafari = /AppleWebKit/g.test(ua) && /Safari/g.test(ua);
  const safari =
    likeSafari &&
    !firefox &&
    !chromium &&
    !edge &&
    ua.match(/(version)\/(\d+)\.(\d|\.)+\s(mobile|safari)/i);

  if (chromium) {
    const browser = chromium[1];
    const version = chromium[2];
    const like = isOpera
      ? ' Opera'
      : isVivaldi
        ? ' Vivaldi'
        : isDuckDuckGo
          ? ' DuckDuckGo'
          : isYandex
            ? ' Yandex'
            : edge
              ? ' Edge'
              : isBrave
                ? ' Brave'
                : '';
    return `${browser} ${version}${like}`;
  } else if (edgios) {
    const browser = edge[1];
    const version = edge[2];
    return `${browser} ${version}`;
  } else if (firefox) {
    const browser = paleMoon ? paleMoon[1] : firefox[1];
    const version = paleMoon ? paleMoon[2] : firefox[2];
    return `${browser} ${version}`;
  } else if (apple && safari) {
    const browser = 'Safari';
    const version = safari[2];
    return `${browser} ${version}`;
  }
  return 'unknown';
}
