import { Module } from './module';
import { dispatchEvent } from './utils/events';
import type { MCFXTracker } from './declarations';

import { parseUrl } from './utils/url';

export class Reviews extends Module {
  ready: boolean;
  retried: number;
  constructor(tracker: MCFXTracker) {
    super(tracker);

    if (!this.tracker.configuration.reviews) {
      return;
    }

    this.render();
  }

  render() {
    const $el = document.getElementById(this.tracker.configuration.reviews.selector);

    if (!$el) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      if (this.retried < 2) {
        setTimeout(function () {
          that.retried++;
          that.render();
        }, 2000);
      }
      return;
    }

    const accountName = this.tracker.configuration.accountName;

    $el.innerHTML = `
    <div class="mcfx-reviews">
      ${this.tracker.configuration.reviews.reviews
        .map((review) => this.renderReview(review, accountName))
        .join('')}
    </div>
    `;
    dispatchEvent('reviews:rendered', this);
  }

  renderReview(review, accountName) {
    const url = parseUrl()?.domain;

    let html = `
    <div class="mcfx-review" itemprop="review" itemscope itemtype="http://schema.org/Review">
      <div itemprop="itemReviewed" itemscope itemtype="https://schema.org/Organization">
        <span itemprop="name" content="${accountName || url}"/>
      </div>
      <span class="mcfx-review-author" itemprop="author" itemscope itemtype="http://schema.org/Person">
        <span itemprop="name">${review.author_name}</span>
      </span>
      <span class="mcfx-review-date" itemprop="datePublished">${review.date}</span>
      <div itemprop="reviewRating" itemscope itemtype="http://schema.org/Rating">
        <meta itemprop="worstRating" content = "1"/>
        <span class="mcfx-review-number-rating">
          <span class="mcfx-review-rating" itemprop="ratingValue">${review.rating}</span>/
          <span class="mcfx-review-max" itemprop="bestRating">5</span> stars
        </span>
        <span class="mcfx-review-stars">
    `;

    for (let i = 1; i <= 5; i++) {
      if (review.rating >= i) {
        html += '<span class="mcfx-star mcfx-star-active"></span>';
      }
    }

    html += `
        </span>
      </div>
      <span class="mcfx-review-description" itemprop="reviewBody">${review.text}</span>
    </div>`;

    return html;
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['reviews']: Reviews;
  }
}
