import { Module } from './module';
import type { MCFXTracker } from './declarations';

export class NutshellBoot extends Module {
  constructor(tracker: MCFXTracker) {
    super(tracker);

    if (typeof this.tracker.configuration.siteId === 'string' && this.tracker.configuration.siteId.substring(0, 3) === 'ns-') {
      const id = Number(this.tracker.configuration.siteId.substring(3));
      this.init(id);
    }
  }

  init(id: number) {
    const bootDiv = document.createElement('div');
    const bootDivId = `nutshell-boot-${id}`;
    bootDiv.id = bootDivId;
    document.body.appendChild(bootDiv);
  
    const boot = document.createElement('script');
    boot.text = this.getScriptText(bootDivId, id);
    document.getElementById(bootDivId).appendChild(boot);
  
    const loader = document.createElement('script');
    loader.src = this.getScriptSource(id);
    loader.async = true;
    document.getElementById(bootDivId).appendChild(loader);
  }
  
  getScriptText(targetId: string, instanceId: number) {
    return `
    <!-- Nutshell Boot -->
    (function(n,u,t){n[u]=n[u]||function(){(n[u].q=n[u].q||[]).push(arguments)}}(window,'Nutsheller'));
    Nutsheller('boot', {instance: ${instanceId}, target: '${targetId}', excludedModules: ['analytics']});
    <!-- End Nutshell Boot -->`;
  }
  
  getScriptSource(instanceId: number) {
    // Instance 1 is the local Nutshell development instance which needs a different source
    return instanceId === 1
      ? 'https://loader.kojo.zone/nutsheller.js'
      : 'https://loader.nutshell.com/nutsheller.js';
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['nutshellBoot']: NutshellBoot;
  }
}
