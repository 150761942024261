export function getHTMLElementVersion() {
  try {
    const keys = [];
    // eslint-disable-next-line guard-for-in
    for (const key in document.documentElement) {
      keys.push(key);
    }
    return { keys };
  } catch (error) {
    return -1;
  }
}
