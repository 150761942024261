import { caniuse } from './utils/helpers';

export async function getIntl() {
  const getLocale = (intl) => {
    const constructors = [
      'Collator',
      'DateTimeFormat',
      'DisplayNames',
      'ListFormat',
      'NumberFormat',
      'PluralRules',
      'RelativeTimeFormat',
    ];
    // @ts-ignore
    const locale = constructors.reduce((acc, name) => {
      try {
        const obj = new intl[name]();
        if (!obj) {
          return acc;
        }
        const { locale } = obj.resolvedOptions() || {};
        return [...acc, locale];
      } catch (error) {
        return acc;
      }
    }, []);

    return [...new Set(locale)];
  };

  try {
    const dateTimeFormat = caniuse(() => {
      return new Intl.DateTimeFormat(undefined, {
        month: 'long',
        timeZoneName: 'long',
      }).format(963644400000);
    });

    const displayNames = caniuse(() => {
      return new Intl.DisplayNames(undefined, {
        type: 'language',
      }).of('en-US');
    });

    const listFormat = caniuse(() => {
      // @ts-ignore
      return new Intl.ListFormat(undefined, {
        style: 'long',
        type: 'disjunction',
      }).format(['0', '1']);
    });

    const numberFormat = caniuse(() => {
      return new Intl.NumberFormat(undefined, {
        notation: 'compact',
        compactDisplay: 'long',
      }).format(21000000);
    });

    const pluralRules = caniuse(() => {
      return new Intl.PluralRules().select(1);
    });

    const relativeTimeFormat = caniuse(() => {
      return new Intl.RelativeTimeFormat(undefined, {
        localeMatcher: 'best fit',
        numeric: 'auto',
        style: 'long',
      }).format(1, 'year');
    });

    const locale = getLocale(Intl);

    return {
      dateTimeFormat,
      displayNames,
      listFormat,
      numberFormat,
      pluralRules,
      relativeTimeFormat,
      locale: '' + locale,
    };
  } catch (error) {
    return undefined;
  }
}
