import { hashMini } from './utils/crypto';
import { isBrave, getOS, getRestoredUserAgent } from '../utils/browser';
import { TRUSTED_NAVIGATOR_BOOLEANS } from './constants';
import {
  getUserAgentPlatform,
  isUAPostReduction,
  getReportedPlatform,
  isGibberish,
  attempt,
} from './utils/helpers';

// special thanks to https://arh.antoinevastel.com for inspiration
export async function getNavigator() {
  try {
    const credibleUserAgent =
      'chrome' in window ? navigator.userAgent.includes(navigator.appVersion) : true;

    const [bluetoothAvailability, permissions, webgpu] = await Promise.all([
      getBluetoothAvailability(),
      getPermissions(),
      getWebGpu(),
    ]);

    return {
      bluetoothAvailability,
      permissions,
      webgpu,
      platform: attempt(() => {
        let lied = false;
        // @ts-expect-error userAgentData is not supported in all browsers
        const { platform, userAgent, userAgentData } = navigator;
        const systems = [
          'win',
          'linux',
          'mac',
          'macos',
          'arm',
          'pike',
          'linux',
          'iphone',
          'ipad',
          'ipod',
          'android',
          'x11',
        ];
        const trusted =
          typeof platform == 'string' &&
          systems.filter((val) => platform.toLowerCase().includes(val))[0];

        const reportedPlatform = getReportedPlatform(
          userAgent,
          platform || userAgentData?.platform
        );

        // user agent os lie
        if (reportedPlatform[0] !== reportedPlatform[1]) {
          lied = true;
        }

        return {
          name: platform,
          trusted,
          lied,
        };
      }),
      system: attempt(() => getOS(navigator.userAgent)),
      userAgentParsed: await attempt(() => {
        const reportedUserAgent = navigator.userAgent;
        const reportedSystem = getOS(reportedUserAgent);
        return {
          ua: isGibberish(navigator.userAgent)
            ? getRestoredUserAgent({
                userAgent: navigator.userAgent,
                // @ts-expect-error userAgentData is not supported in all browsers
                userAgentData: navigator.userAgentData,
                fontPlatformVersion: '',
              })
            : navigator.userAgent,
          os: reportedSystem,
          isBrave: isBrave(),
        };
      }),
      device: attempt(
        () => getUserAgentPlatform({ userAgent: navigator.userAgent }),
        'userAgent device failed'
      ),
      userAgent: navigator.userAgent,
      uaPostReduction: isUAPostReduction((navigator || {}).userAgent),
      appVersion: attempt(() => {
        const { appVersion } = navigator;
        return appVersion.trim().replace(/\s{2,}/, ' ');
      }),
      deviceMemory: attempt(() => {
        if (!('deviceMemory' in navigator)) {
          return undefined;
        }

        // @ts-ignore
        const { deviceMemory }: { deviceMemory: number } = navigator;
        const trusted = {
          '0.25': true,
          '0.5': true,
          '1': true,
          '2': true,
          '4': true,
          '8': true,
        };

        // @ts-expect-error memory is undefined if not supported
        const memory: number | null = performance?.memory?.jsHeapSizeLimit || null;
        const memoryInGigabytes: number = memory ? +(memory / 1073741824).toFixed(1) : 0;
        if (memoryInGigabytes > deviceMemory) {
          return undefined;
        }

        return deviceMemory;
      }),
      doNotTrack: attempt(() => {
        const { doNotTrack } = navigator;
        const trustedVals = {
          '1': true,
          true: true,
          yes: true,
          '0': false,
          false: false,
          no: false,
          unspecified: false,
          null: false,
          undefined: false,
        };

        return TRUSTED_NAVIGATOR_BOOLEANS[doNotTrack] ?? false;
      }),
      globalPrivacyControl: attempt(() => {
        // @ts-ignore
        const { globalPrivacyControl }: { globalPrivacyControl: any } = navigator;
        return TRUSTED_NAVIGATOR_BOOLEANS[globalPrivacyControl] ?? false;
      }),
      hardwareConcurrency: navigator.hardwareConcurrency,
      language: attempt(() => {
        const { language, languages } = navigator;

        if (language && languages) {
          // @ts-ignore
          const lang = /^.{0,2}/g.exec(language)[0];
          // @ts-ignore
          const langs = /^.{0,2}/g.exec(languages[0])[0];
          if (langs != lang) {
            return 'undefined';
          }

          return `${languages.filter((l) => l !== lang).join(', ')} (${language})`;
        }

        if (language) {
          return language;
        }

        if (languages) {
          return languages.join(', ');
        }

        return '';
      }),
      maxTouchPoints: navigator.maxTouchPoints,
      vendor: navigator.vendor,
      mimeTypes: attempt(() => {
        const { mimeTypes } = navigator;
        return mimeTypes ? [...mimeTypes].map((m) => m.type).join(', ') : '';
      }),
      // @ts-ignore
      oscpu: navigator.oscpu,
    };
  } catch (error) {
    return undefined;
  }
}

function getBluetoothAvailability() {
  if (
    !('bluetooth' in navigator) ||
    // @ts-ignore
    !navigator.bluetooth ||
    // @ts-ignore
    !navigator.bluetooth.getAvailability
  ) {
    return undefined;
  }
  // @ts-ignore
  return navigator.bluetooth.getAvailability();
}

async function getPermissions() {
  const getPermissionState = (name) =>
    navigator.permissions
      .query({ name })
      .then((res) => ({ name, state: res.state }))
      .catch((error) => ({ name, state: 'unknown' }));

  // https://w3c.github.io/permissions/#permission-registry
  const permissions = !('permissions' in navigator)
    ? []
    : await Promise.all(
        [
          'accelerometer',
          'ambient-light-sensor',
          'background-fetch',
          'background-sync',
          'bluetooth',
          'camera',
          'clipboard',
          'device-info',
          'display-capture',
          'gamepad',
          'geolocation',
          'gyroscope',
          'magnetometer',
          'microphone',
          'midi',
          'nfc',
          'notifications',
          'persistent-storage',
          'push',
          'screen-wake-lock',
          'speaker',
          'speaker-selection',
        ].map(getPermissionState)
      );

  permissions.reduce((acc, perm) => {
    if (!perm) {
      return acc;
    }

    const { state, name } = perm;
    if (acc[state]) {
      acc[state].push(name);
      return acc;
    }
    acc[state] = [name];
    return acc;
  }, {});

  return permissions;
}

async function getWebGpu() {
  if (!('gpu' in navigator)) {
    return;
  }
  try {
    const [adapter, info] = await Promise.all([
      // @ts-expect-error if unsupported
      navigator.gpu.requestAdapter(),
      // @ts-expect-error if unsupported
      navigator.gpu.requestAdapterInfo(),
    ]);

    if (!adapter) return undefined;
    const { limits = {}, features = [] } = adapter || {};

    const { architecture, description, device, vendor } = info;
    const adapterInfo = [vendor, architecture, description, device];
    const featureValues = [...features.values()];
    const limitsData = ((limits) => {
      const data: Record<string, number> = {};
      // eslint-disable-next-line guard-for-in
      for (const prop in limits) {
        data[prop] = limits[prop];
      }
      return data;
    })(limits);

    return {
      adapterInfo,
      features: featureValues,
      limits: limitsData,
    };
  } catch (error) {
    return undefined;
  }
}
