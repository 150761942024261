import { LowerEntropy, getRandomValues, getReportedPlatform } from './utils/helpers';
import { patch, html } from './utils/html';
import {
  EMOJIS,
  FONT_LIST,
  MacOSFonts,
  CSS_FONT_FAMILY,
  WindowsFonts,
  DesktopAppFonts,
  PlatformClassifier,
} from './constants';

export async function getFonts(phantomWindow) {
  try {
    const doc = phantomWindow?.document?.body ? phantomWindow.document : document;
    const id = `ffp`;
    const div = doc.createElement('div');
    div.setAttribute('id', id);
    doc.body.appendChild(div);

    const { emojiSet, pixelSizeSystemSum } =
      getPixelEmojis({
        doc,
        id,
        emojis: EMOJIS,
      }) || {};

    const fontList = FONT_LIST;
    const fontFaceLoadFonts = await getFontFaceLoadFonts(fontList);
    const platformVersion = getPlatformVersion(fontFaceLoadFonts);
    const apps = getDesktopApps(fontFaceLoadFonts);

    // detect lies
    let trust = true;
    const { userAgent, platform } = self.navigator || {};
    const [uaOS, PLATFORM_OS] = getReportedPlatform(userAgent, platform);

    if (isFontOSBad(uaOS, fontFaceLoadFonts)) {
      trust = false;
    }

    return {
      fontFaceLoadFonts,
      platformVersion,
      apps,
      emojiSet,
      pixelSizeSystemSum,
      trust,
    };
  } catch (error) {
    return undefined;
  }
}

export function isFontOSBad(userAgentOS: string, fonts: string[]): boolean {
  if (!userAgentOS || !fonts || !fonts.length) return false;

  const fontMap = fonts.reduce(
    (acc, x) => {
      acc[x] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  const isLikeWindows =
    'Cambria Math' in fontMap ||
    'Nirmala UI' in fontMap ||
    'Leelawadee UI' in fontMap ||
    'HoloLens MDL2 Assets' in fontMap ||
    'Segoe Fluent Icons' in fontMap;

  const isLikeApple =
    'Helvetica Neue' in fontMap ||
    'Luminari' in fontMap ||
    'PingFang HK Light' in fontMap ||
    'InaiMathi Bold' in fontMap ||
    'Galvji' in fontMap ||
    'Chakra Petch' in fontMap;

  const isLikeLinux =
    'Arimo' in fontMap ||
    'MONO' in fontMap ||
    'Ubuntu' in fontMap ||
    'Noto Color Emoji' in fontMap ||
    'Dancing Script' in fontMap ||
    'Droid Sans Mono' in fontMap;

  if (isLikeWindows && userAgentOS != PlatformClassifier.WINDOWS) {
    return true;
  } else if (isLikeApple && userAgentOS != PlatformClassifier.APPLE) {
    return true;
  } else if (isLikeLinux && userAgentOS != PlatformClassifier.LINUX) {
    return true;
  }
  return false;
}

function getPixelEmojis({ doc, id, emojis }) {
  try {
    patch(
      doc.getElementById(id),
      html`
        <div id="pixel-emoji-container">
          <style>
            .pixel-emoji {
              font-family: ${CSS_FONT_FAMILY};
              font-size: 200px !important;
              height: auto;
              position: absolute !important;
              transform: scale(1.000999);
            }
          </style>
          ${emojis
            .map((emoji: string) => {
              return `<div class="pixel-emoji">${emoji}</div>`;
            })
            .join('')}
        </div>
      `
    );

    // get emoji set and system
    const getEmojiDimensions = (style) => {
      return {
        width: style.inlineSize,
        height: style.blockSize,
      };
    };

    const pattern = new Set();
    const emojiElems = [...doc.getElementsByClassName('pixel-emoji')];
    const emojiSet = emojiElems.reduce((emojiSet, el, i) => {
      const style = getComputedStyle(el);
      const emoji = emojis[i];
      const { height, width } = getEmojiDimensions(style);
      const dimensions = `${width},${height}`;
      if (!pattern.has(dimensions)) {
        pattern.add(dimensions);
        emojiSet.add(emoji);
      }
      return emojiSet;
    }, new Set());

    const pixelToNumber = (pixels) => +pixels.replace('px', '');
    const pixelSizeSystemSum =
      0.00001 *
      [...pattern]
        .map((x: string) => {
          return x
            .split(',')
            .map((x) => pixelToNumber(x))
            .reduce((acc, x) => (acc += +x || 0), 0);
        })
        .reduce((acc, x) => (acc += x), 0);

    doc.body.removeChild(doc.getElementById('pixel-emoji-container'));

    return {
      emojiSet: [...emojiSet],
      pixelSizeSystemSum,
    };
  } catch (error) {
    console.error(error);
    return {
      emojiSet: [],
      pixelSizeSystemSum: 0,
    };
  }
}

async function getFontFaceLoadFonts(fontList: string[]) {
  try {
    let fontsChecked: string[] = [];
    if (!document.fonts.check(`0px "${getRandomValues()}"`)) {
      fontsChecked = fontList.reduce((acc, font) => {
        const found = document.fonts.check(`0px "${font}"`);
        if (found) acc.push(font);
        return acc;
      }, [] as string[]);
    }
    const fontFaceList = fontList.map((font) => new FontFace(font, `local("${font}")`));
    const responseCollection = await Promise.allSettled(fontFaceList.map((font) => font.load()));
    const fontsLoaded = responseCollection.reduce((acc, font) => {
      if (font.status == 'fulfilled') {
        acc.push(font.value.family);
      }
      return acc;
    }, [] as string[]);
    return [...new Set([...fontsChecked, ...fontsLoaded])].sort();
  } catch (error) {
    console.error(error);
    return [];
  }
}

const getPlatformVersion = (fonts) => {
  const getWindows = ({ fonts, fontMap }) => {
    const fontVersion = {
      ['11']: fontMap['11'].find((x) => fonts.includes(x)),
      ['10']: fontMap['10'].find((x) => fonts.includes(x)),
      ['8.1']: fontMap['8.1'].find((x) => fonts.includes(x)),
      ['8']: fontMap['8'].find((x) => fonts.includes(x)),
      // require complete set of Windows 7 fonts
      ['7']: fontMap['7'].filter((x) => fonts.includes(x)).length == fontMap['7'].length,
    };
    const hash =
      '' +
      Object.keys(fontVersion)
        .sort()
        .filter((key) => !!fontVersion[key]);
    const hashMap = {
      '10,11,7,8,8.1': '11',
      '10,7,8,8.1': '10',
      '7,8,8.1': '8.1',
      '11,7,8,8.1': '8.1', // missing 10
      '7,8': '8',
      '10,7,8': '8', // missing 8.1
      '10,11,7,8': '8', // missing 8.1
      '7': '7',
      '7,8.1': '7',
      '10,7,8.1': '7', // missing 8
      '10,11,7,8.1': '7', // missing 8
    };
    const version = hashMap[hash];
    return version ? `Windows ${version}` : undefined;
  };

  const getMacOS = ({ fonts, fontMap }) => {
    const fontVersion = {
      ['13']: fontMap['13'].find((x) => fonts.includes(x)),
      ['12']: fontMap['12'].find((x) => fonts.includes(x)),
      ['10.15-11']: fontMap['10.15-11'].find((x) => fonts.includes(x)),
      ['10.13-10.14']: fontMap['10.13-10.14'].find((x) => fonts.includes(x)),
      ['10.12']: fontMap['10.12'].find((x) => fonts.includes(x)),
      ['10.11']: fontMap['10.11'].find((x) => fonts.includes(x)),
      ['10.10']: fontMap['10.10'].find((x) => fonts.includes(x)),
      // require complete set of 10.9 fonts
      ['10.9']: fontMap['10.9'].filter((x) => fonts.includes(x)).length == fontMap['10.9'].length,
    };
    const hash =
      '' +
      Object.keys(fontVersion)
        .sort()
        .filter((key) => !!fontVersion[key]);
    const hashMap = {
      '10.10,10.11,10.12,10.13-10.14,10.15-11,10.9,12,13': 'Ventura',
      '10.10,10.11,10.12,10.13-10.14,10.15-11,10.9,12': 'Monterey',
      '10.10,10.11,10.12,10.13-10.14,10.15-11,10.9': '10.15-11',
      '10.10,10.11,10.12,10.13-10.14,10.9': '10.13-10.14',
      '10.10,10.11,10.12,10.9': 'Sierra', // 10.12
      '10.10,10.11,10.9': 'El Capitan', // 10.11
      '10.10,10.9': 'Yosemite', // 10.10
      '10.9': 'Mavericks', // 10.9
    };
    const version = hashMap[hash];
    return version ? `macOS ${version}` : undefined;
  };

  return getWindows({ fonts, fontMap: WindowsFonts }) || getMacOS({ fonts, fontMap: MacOSFonts });
};

const getDesktopApps = (fonts) => {
  // @ts-ignore
  const apps = Object.keys(DesktopAppFonts).reduce((acc, key) => {
    const appFontSet = DesktopAppFonts[key];
    const match = appFontSet.filter((x) => fonts.includes(x)).length == appFontSet.length;
    return match ? [...acc, key] : acc;
  }, []);
  return apps;
};
