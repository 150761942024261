export const TRUSTED_NAVIGATOR_BOOLEANS = {
  '1': true,
  true: true,
  yes: true,
  '0': false,
  false: false,
  no: false,
  unspecified: false,
  null: false,
  undefined: false,
};

export const enum PlatformClassifier {
  WINDOWS = 'Windows',
  LINUX = 'Linux',
  APPLE = 'Apple',
  OTHER = 'Other',
}

// inspired by Lalit Patel's fontdetect.js
// https://www.lalit.org/wordpress/wp-content/uploads/2008/05/fontdetect.js?ver=0.3
export const WindowsFonts = {
  // https://docs.microsoft.com/en-us/typography/fonts/windows_11_font_list
  '7': ['Cambria Math', 'Lucida Console'],
  '8': ['Aldhabi', 'Gadugi', 'Myanmar Text', 'Nirmala UI'],
  '8.1': ['Leelawadee UI', 'Javanese Text', 'Segoe UI Emoji'],
  '10': [
    'HoloLens MDL2 Assets', // 10 (v1507) +
    'Segoe MDL2 Assets', // 10 (v1507) +
    'Bahnschrift', // 10 (v1709) +-
    'Ink Free', // 10 (v1803) +-
  ],
  '11': ['Segoe Fluent Icons'],
};

export const MacOSFonts = {
  // Mavericks and below
  '10.9': [
    'Helvetica Neue',
    'Geneva', // mac (not iOS)
  ],
  // Yosemite
  '10.10': ['Kohinoor Devanagari Medium', 'Luminari'],
  // El Capitan
  '10.11': ['PingFang HK Light'],
  // Sierra: https://support.apple.com/en-ie/HT206872
  '10.12': ['American Typewriter Semibold', 'Futura Bold', 'SignPainter-HouseScript Semibold'],
  // High Sierra: https://support.apple.com/en-me/HT207962
  // Mojave: https://support.apple.com/en-us/HT208968
  '10.13-10.14': ['InaiMathi Bold'],
  // Catalina: https://support.apple.com/en-us/HT210192
  // Big Sur: https://support.apple.com/en-sg/HT211240
  '10.15-11': ['Galvji', 'MuktaMahee Regular'],
  // Monterey: https://support.apple.com/en-us/HT212587
  '12': [
    'Noto Sans Gunjala Gondi Regular',
    'Noto Sans Masaram Gondi Regular',
    'Noto Serif Yezidi Regular',
  ],
  // Ventura: https://support.apple.com/en-us/HT213266
  '13': [
    'Apple SD Gothic Neo ExtraBold',
    'STIX Two Math Regular',
    'STIX Two Text Regular',
    'Noto Sans Canadian Aboriginal Regular',
  ],
};

export const DesktopAppFonts = {
  // docs.microsoft.com/en-us/typography/font-list/ms-outlook
  'Microsoft Outlook': ['MS Outlook'],
  // https://community.adobe.com/t5/postscript-discussions/zwadobef-font/m-p/3730427#M785
  'Adobe Acrobat': ['ZWAdobeF'],
  // https://wiki.documentfoundation.org/Fonts
  LibreOffice: ['Amiri', 'KACSTOffice', 'Liberation Mono', 'Source Code Pro'],
  // https://superuser.com/a/611804
  OpenOffice: ['DejaVu Sans', 'Gentium Book Basic', 'OpenSymbol'],
};

export const APPLE_FONTS = Object.keys(MacOSFonts)
  .map((key) => MacOSFonts[key])
  .flat();
export const WINDOWS_FONTS = Object.keys(WindowsFonts)
  .map((key) => WindowsFonts[key])
  .flat();
export const DESKTOP_APP_FONTS = Object.keys(DesktopAppFonts)
  .map((key) => DesktopAppFonts[key])
  .flat();
export const LINUX_FONTS = [
  'Arimo', // ubuntu, chrome os
  'Chilanka', // ubuntu (not TB)
  'Cousine', // ubuntu, chrome os
  'Jomolhari', // chrome os
  'MONO', // ubuntu, chrome os (not TB)
  'Noto Color Emoji', // Linux
  'Ubuntu', // ubuntu (not TB)
];
export const ANDROID_FONTS = [
  'Dancing Script', // android
  'Droid Sans Mono', // Android
  'Roboto', // Android, Chrome OS
];

export const FONT_LIST = [
  ...APPLE_FONTS,
  ...WINDOWS_FONTS,
  ...LINUX_FONTS,
  ...ANDROID_FONTS,
  ...DESKTOP_APP_FONTS,
].sort();

export const EMOJIS = [
  [128512],
  [9786],
  [129333, 8205, 9794, 65039],
  [9832],
  [9784],
  [9895],
  [8265],
  [8505],
  [127987, 65039, 8205, 9895, 65039],
  [129394],
  [9785],
  [9760],
  [129489, 8205, 129456],
  [129487, 8205, 9794, 65039],
  [9975],
  [129489, 8205, 129309, 8205, 129489],
  [9752],
  [9968],
  [9961],
  [9972],
  [9992],
  [9201],
  [9928],
  [9730],
  [9969],
  [9731],
  [9732],
  [9976],
  [9823],
  [9937],
  [9000],
  [9993],
  [9999],

  [128105, 8205, 10084, 65039, 8205, 128139, 8205, 128104],
  [128104, 8205, 128105, 8205, 128103, 8205, 128102],
  [128104, 8205, 128105, 8205, 128102],

  // android 11
  [128512],
  [169],
  [174],
  [8482],
  [128065, 65039, 8205, 128488, 65039],

  // other
  [10002],
  [9986],
  [9935],
  [9874],
  [9876],
  [9881],
  [9939],
  [9879],
  [9904],
  [9905],
  [9888],
  [9762],
  [9763],
  [11014],
  [8599],
  [10145],
  [11013],
  [9883],
  [10017],
  [10013],
  [9766],
  [9654],
  [9197],
  [9199],
  [9167],
  [9792],
  [9794],
  [10006],
  [12336],
  [9877],
  [9884],
  [10004],
  [10035],
  [10055],
  [9724],
  [9642],
  [10083],
  [10084],
  [9996],
  [9757],
  [9997],
  [10052],
  [9878],
  [8618],
  [9775],
  [9770],
  [9774],
  [9745],
  [10036],
  [127344],
  [127359],
].map((emojiCode) => String.fromCodePoint(...emojiCode));

export const CSS_FONT_FAMILY = `
	'Segoe Fluent Icons',
	'Ink Free',
	'Bahnschrift',
	'Segoe MDL2 Assets',
	'HoloLens MDL2 Assets',
	'Leelawadee UI',
	'Javanese Text',
	'Segoe UI Emoji',
	'Aldhabi',
	'Gadugi',
	'Myanmar Text',
	'Nirmala UI',
	'Lucida Console',
	'Cambria Math',
	'Bai Jamjuree',
	'Chakra Petch',
	'Charmonman',
	'Fahkwang',
	'K2D',
	'Kodchasan',
	'KoHo',
	'Sarabun',
	'Srisakdi',
	'Galvji',
	'MuktaMahee Regular',
	'InaiMathi Bold',
	'American Typewriter Semibold',
	'Futura Bold',
	'SignPainter-HouseScript Semibold',
	'PingFang HK Light',
	'Kohinoor Devanagari Medium',
	'Luminari',
	'Geneva',
	'Helvetica Neue',
	'Droid Sans Mono',
	'Dancing Script',
	'Roboto',
	'Ubuntu',
	'Liberation Mono',
	'Source Code Pro',
	'DejaVu Sans',
	'OpenSymbol',
	'Chilanka',
	'Cousine',
	'Arimo',
	'Jomolhari',
	'MONO',
	'Noto Color Emoji',
	sans-serif !important
`;

export const WEBGL_RENDERER_KNOWN_PARTS = [
  'Ada',
  'Alchemist',
  'AMD',
  'ANGLE',
  'Arc Graphics',
  'ASUS',
  'ATI',
  'ATI Radeon',
  'ATI Technologies Inc',
  'Adreno',
  'Android Emulator',
  'Apple',
  'Apple GPU',
  'Apple M1',
  'Apple M2',
  'Apple M3',
  'Chipset',
  'D3D11',
  'Direct3D',
  'Express Chipset',
  'GeForce',
  'Generation',
  'Generic Renderer',
  'Google',
  'Google SwiftShader',
  'Graphics',
  'Graphics Media Accelerator',
  'HD Graphics Family',
  'Intel',
  'Intel Arc',
  'Intel(R) HD Graphics',
  'Intel(R) UHD Graphics',
  'Iris',
  'KBL Graphics',
  'Mali',
  'Mesa',
  'Mesa DRI',
  'Metal',
  'Microsoft',
  'Microsoft Basic Render Driver',
  'Microsoft Corporation',
  'NVIDIA',
  'NVIDIA Corporation',
  'NVIDIAGameReadyD3D',
  'OpenGL',
  'OpenGL Engine',
  'Open Source Technology Center',
  'Parallels',
  'Parallels Display Adapter',
  'PCIe',
  'Plus Graphics',
  'PowerVR',
  'Pro Graphics',
  'Quadro',
  'RDNA',
  'RTX',
  'Radeon',
  'Radeon Pro',
  'Radeon Pro Vega',
  'Radeon RX',
  'RX',
  'Samsung',
  'Silicon',
  'SSE2',
  'VMware',
  'VMware SVGA 3D',
  'Vega',
  'VirtualBox',
  'VirtualBox Graphics Adapter',
  'Vulkan',
  'Xe Graphics',
  'llvmpipe',
];

export const WEBGL_CONTEXT_PARAMETERS = new Set([
  'ALIASED_LINE_WIDTH_RANGE',
  'ALIASED_POINT_SIZE_RANGE',
  'MAX_3D_TEXTURE_SIZE',
  'MAX_ARRAY_TEXTURE_LAYERS',
  'MAX_CLIENT_WAIT_TIMEOUT_WEBGL',
  'MAX_COLOR_ATTACHMENTS',
  'MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS',
  'MAX_COMBINED_TEXTURE_IMAGE_UNITS',
  'MAX_COMBINED_UNIFORM_BLOCKS',
  'MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS',
  'MAX_CUBE_MAP_TEXTURE_SIZE',
  'MAX_DRAW_BUFFERS',
  'MAX_ELEMENT_INDEX',
  'MAX_ELEMENTS_INDICES',
  'MAX_ELEMENTS_VERTICES',
  'MAX_FRAGMENT_INPUT_COMPONENTS',
  'MAX_FRAGMENT_UNIFORM_BLOCKS',
  'MAX_FRAGMENT_UNIFORM_COMPONENTS',
  'MAX_FRAGMENT_UNIFORM_VECTORS',
  'MAX_PROGRAM_TEXEL_OFFSET',
  'MAX_RENDERBUFFER_SIZE',
  'MAX_SAMPLES',
  'MAX_SERVER_WAIT_TIMEOUT',
  'MAX_TEXTURE_IMAGE_UNITS',
  'MAX_TEXTURE_LOD_BIAS',
  'MAX_TEXTURE_SIZE',
  'MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS',
  'MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS',
  'MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS',
  'MAX_UNIFORM_BLOCK_SIZE',
  'MAX_UNIFORM_BUFFER_BINDINGS',
  'MAX_VARYING_COMPONENTS',
  'MAX_VARYING_VECTORS',
  'MAX_VERTEX_ATTRIBS',
  'MAX_VERTEX_OUTPUT_COMPONENTS',
  'MAX_VERTEX_TEXTURE_IMAGE_UNITS',
  'MAX_VERTEX_UNIFORM_BLOCKS',
  'MAX_VERTEX_UNIFORM_COMPONENTS',
  'MAX_VERTEX_UNIFORM_VECTORS',
  'MAX_VIEWPORT_DIMS',
  'RENDERER',
  'SHADING_LANGUAGE_VERSION',
  'STENCIL_BACK_VALUE_MASK',
  'STENCIL_BACK_WRITEMASK',
  'STENCIL_VALUE_MASK',
  'STENCIL_WRITEMASK',
  'SUBPIXEL_BITS',
  'VENDOR',
  'VERSION',
]);

export const GIBBERS =
  /[cC]f|[jJ][bcdfghlmprsty]|[qQ][bcdfghjklmnpsty]|[vV][bfhjkmpt]|[xX][dkrz]|[yY]y|[zZ][fr]|[cCxXzZ]j|[bBfFgGjJkKpPvVqQtTwWyYzZ]q|[cCfFgGjJpPqQwW]v|[jJqQvV]w|[bBcCdDfFgGhHjJkKmMpPqQsSvVwWxXzZ]x|[bBfFhHjJkKmMpPqQ]z/g;
